import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../Auth/AuthContext'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import { types } from '../../Types/types'
import Swal from 'sweetalert2'
import { useApolloClient } from '@apollo/client'
import withReactContent from 'sweetalert2-react-content'
import AlquimiaLogo from './Ojo-2.png'

export const Header = () => {
  const { dispatch, user } = useContext(AuthContext)

  const client = useApolloClient()
  const MySwal = withReactContent(Swal)

  const handleLogout = () => {
    MySwal.fire({
      icon: 'info',
      showCloseButton: true,
      showDenyButton: true,
      reverseButtons: true,
      title: 'Cerrar sesión',
      html: (
        <div className="message-container">¿Estás seguro de cerrar sesión?</div>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Cerrar sesión',
      denyButtonText: 'Cancelar',
      focusDeny: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          client.clearStore()
          sessionStorage.removeItem('user')
          sessionStorage.removeItem('requestPath')
          localStorage.removeItem('token')
          localStorage.removeItem('configSys')
          localStorage.removeItem('platform')
          toastSweetAlert({
            mode: 'ok',
            message: 'Cerrar sesión con éxito',
          })
          setTimeout(() => {
            dispatch({
              type: types.logout,
            })
          }, 400)
        } catch (error) {
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  const resizeHanlder = () => {
    const width = window.innerWidth
    if (width <= 991) {
      document.getElementById('sidebarMain').className =
        'sidebar-mini layout-fixed sidebar-closed sidebar-collapse'
    }
  }

  useEffect(() => {
    window.onresize = resizeHanlder
  }, [])

  return (
    <nav
      id='navbar'
      className={`${user.role != 2 && user.role != 3 && 'main-header'
        } navbar navbar-expand navbar-white navbar-light ${user.role == 3 && 'd-none'
        }`}
    >
      {/* <!-- Left navbar links --> */}
      {user.role != 2 && user.role != 3 && (
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link menuBurguer"
              data-widget="pushmenu"
              href="/#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
      )}

      {/* <div className='logos'>
        <img src={AlquimiaLogo} alt="alquimia-logo" />
      </div> */}

      {/* <!-- Right navbar links --> */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item" onClick={() => handleLogout()}>
          <a
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            href="/#"
            role="button"
          >
            <i className="fas fa-power-off"></i>
          </a>
        </li>
      </ul>
    </nav>
  )
}
export default Header
