import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import RecordsReportItem from './RecordsReportItem'
import { GET_RECORDS_REPORT } from '../../../graphql/Document/RecordsReport/RecordsReport'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import Logo from '../../../Assets/Images/votes/logo-2.png'

const RecordsReport = () => {
  //------- Hooks --------
  const [records, setRecords] = useState({
    global: {
      totalUsers: 0,
      genders: [],
      ages: [],
    },
    friday: {
      totalUsers: 0,
      genders: [],
      ages: [],
      femaleAges: [],
      maleAges: [],
    },
    saturday: {
      totalUsers: 0,
      genders: [],
      ages: [],
      femaleAges: [],
      maleAges: [],
    },
  })
  const [loadingRecords, setLoadingRecords] = useState(true)

  //------- Queries and Mutations ------
  const { data, loading, error } = useQuery(GET_RECORDS_REPORT)

  //------- UseEffects --------
  useEffect(() => {
    if (!loading) {
      setLoadingRecords(true)
      if (error) {
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })
      }

      //Get data
      if (data?.getRecordsReport) {
        const { global, friday, saturday } = data?.getRecordsReport

        //Prepare data
        let globalTextTotal = `Tuvimos un total de registros de ${global?.totalUsers} personas de los cuales ${friday?.totalUsers} 
        corresponden al viernes y ${saturday?.totalUsers} al sábado.`

        let globalDataTotal = [
          { name: 'Viernes', y: friday?.totalUsers },
          { name: 'Sabado', y: saturday?.totalUsers },
        ]

        let globalTextGender = `De acuerdo a su sexo el total de registro fue
          ${global?.genders[0].y ? global.genders[0].y : '0'} ${global?.genders[0].name}
          y ${global?.genders[1].y ? global.genders[1].y : '0'} ${global?.genders[1].name}.`

        let globalTextAge = `De acuerdo a su edad, tenemos `
        let globalArrayAge = []
        for (const age of global?.ages) {
          globalTextAge += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          globalArrayAge.push(age?.name)
        }

        let fridayTextGender = `El viernes tuvimos un registro de ${friday.totalUsers}
          asistentes, de los cuales`
        for (const gender of friday?.genders) {
          fridayTextGender += ` ${gender.y} son ${gender.name}.`
        }

        let fridayTextAge = `De acuerdo a su edad, tenemos `
        let fridayArrayAge = []
        for (const age of friday?.ages) {
          fridayTextAge += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          fridayArrayAge.push(age?.name)
        }

        let fridayTextFemaleAgesTotals = `El día viernes el registro de mujeres fue de ${friday?.genders[0]?.y}. De acuerdo a su edad, `
        let fridayArrayFemaleAgesTotals = []
        for (const age of friday?.femaleAges) {
          fridayTextFemaleAgesTotals += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          fridayArrayFemaleAgesTotals.push(age?.name)
        }

        let fridayTextMaleAgesTotals = `El día viernes el registro de hombres fue de ${friday?.genders[1]?.y}. De acuerdo a su edad, `
        let fridayArrayMaleAgesTotals = []
        for (const age of friday?.maleAges) {
          fridayTextMaleAgesTotals += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          fridayArrayMaleAgesTotals.push(age?.name)
        }

        let saturdayTextGender = `El sabado tuvimos un registro de ${saturday.totalUsers}
        asistentes, de los cuales`
        for (const gender of saturday?.genders) {
          saturdayTextGender += ` ${gender.y} son ${gender.name}.`
        }

        let saturdayTextAge = `De acuerdo a su edad, tenemos `
        let saturdayArrayAge = []
        for (const age of saturday?.ages) {
          saturdayTextAge += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          saturdayArrayAge.push(age?.name)
        }

        let saturdayTextFemaleAgesTotals = `El día sabado el registro de mujeres fue de ${saturday?.genders[0]?.y}. De acuerdo a su edad, `
        let saturdayArrayFemaleAgesTotals = []
        for (const age of saturday?.femaleAges) {
          saturdayTextFemaleAgesTotals += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          saturdayArrayFemaleAgesTotals.push(age?.name)
        }

        let saturdayTextMaleAgesTotals = `El día sabado el registro de hombres fue de ${saturday?.genders[1]?.y}. De acuerdo a su edad, `
        let saturdayArrayMaleAgesTotals = []
        for (const age of saturday?.maleAges) {
          saturdayTextMaleAgesTotals += `${age?.name} con un total de ${age?.y}  ${age?.y == 1 ? 'registro' : 'registros'}. `
          saturdayArrayMaleAgesTotals.push(age?.name)
        }

        setRecords({
          global: {
            ...global,
            globalTextTotal,
            globalTextGender,
            globalTextAge,
            globalArrayAge,
            globalDataTotal,
          },
          friday: {
            ...friday,
            fridayTextGender,
            fridayTextAge,
            fridayArrayAge,
            fridayTextFemaleAgesTotals,
            fridayArrayFemaleAgesTotals,
            fridayTextMaleAgesTotals,
            fridayArrayMaleAgesTotals,
          },
          saturday: {
            ...saturday,
            saturdayTextGender,
            saturdayTextAge,
            saturdayArrayAge,
            saturdayTextFemaleAgesTotals,
            saturdayArrayFemaleAgesTotals,
            saturdayTextMaleAgesTotals,
            saturdayArrayMaleAgesTotals,
          }
        })
      }
      setLoadingRecords(false)

    }
  }, [data, loading, error])

  return (
    <>
      {!loading && !loadingRecords && (
        <div id='recordsReport'>
          <div className='row header'>
            <div className='col-12'>
              <img src={Logo} alt="alquimia-logo" />
            </div>
          </div>
          <RecordsReportItem
            title='Total'
            description={records?.global?.globalTextTotal}
            data={records?.global?.globalDataTotal}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            }}
          />

          <RecordsReportItem
            title='Sexo'
            description={records?.global?.globalTextGender}
            data={records?.global?.genders}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Edad'
            description={records?.global?.globalTextAge}
            data={records?.global?.ages}
            // type='column'
            categories={records?.global?.globalArrayAge}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <div className='row section'>
            <div className='col-12 description'><h2>REGISTRO VIERNES</h2></div>
          </div>

          <RecordsReportItem
            title='Sexo'
            description={records?.friday?.fridayTextGender}
            data={records?.friday?.genders}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Edad'
            description={records?.friday?.fridayTextAge}
            data={records?.friday?.ages}
            // type='column'
            categories={records?.friday?.fridayArrayAge}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Mujeres'
            description={records?.friday?.fridayTextFemaleAgesTotals}
            data={records?.friday?.femaleAges}
            type='pie'
            categories={records?.friday?.fridayArrayFemaleAgesTotals}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Hombres'
            description={records?.friday?.fridayTextMaleAgesTotals}
            data={records?.friday?.maleAges}
            type='pie'
            categories={records?.friday?.fridayArrayMaleAgesTotals}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <div className='row section'>
            <div className='col-12 description'><h2>REGISTRO SABADO</h2></div>
          </div>

          <RecordsReportItem
            title='Sexo'
            description={records?.saturday?.saturdayTextGender}
            data={records?.saturday?.genders}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Edad'
            description={records?.saturday?.saturdayTextAge}
            data={records?.saturday?.ages}
            // type='column'
            categories={records?.saturday?.saturdayArrayAge}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Mujeres'
            description={records?.saturday?.saturdayTextFemaleAgesTotals}
            data={records?.saturday?.femaleAges}
            type='pie'
            categories={records?.saturday?.saturdayArrayFemaleAgesTotals}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <RecordsReportItem
            title='Hombres'
            description={records?.saturday?.saturdayTextMaleAgesTotals}
            data={records?.saturday?.maleAges}
            type='pie'
            categories={records?.saturday?.saturdayArrayMaleAgesTotals}
            tooltip={{
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            }}
          />

          <div className='row'>
            <div className='col-12 description notesBox'>
              <h2>ANOTACIONES EXTRA</h2>
              <ul>
                <li>5 de cada 10 personas se quejaron del registro, ya que no se les comentó que era obligatorio.</li>
                <li>4 de cada 10 personas que esperaban en la fila haciendo su registro se quejaron porque no se avisó con anticipación y las condiciones (sol) no eran óptimas para hacerlo.</li>
                <li>6 de cada 10 personas confundían el registro con la compra de boletos en Boletomovil.</li>
                <li>1 de cada 25 personas nos solicitó apoyo con el registro ya que no contaban con teléfono celular a la mano o preferían no hacerlo desde su teléfono.</li>
                <li>1 de cada 100 personas se negó a hacer el registro comentando que ya habían pagado su boleto y no tenían por qué dar sus datos personales.</li>
                <li>1 de cada 200 personas tenía como idioma nativo uno diferente al castellano.</li>
                <li>1 de cada 50 personas se quejó del registro comentando que solo era para recoger sus datos personales.</li>
              </ul>

            </div>
          </div>

          <div className='row section'>
            <div className='col-12 description'>
              <h2>GRACIAS</h2>
            </div>
          </div>

        </div>
      )}
    </>
  )
}

export default RecordsReport
