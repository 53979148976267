import { gql } from '@apollo/client'

export const GET_ALL_GENDERS = gql`
  query GetAllGenders($searchQuery: String, $limit: Int, $offset: Int) {
    getAllGenders(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
      }
      count
    }
  }
`
