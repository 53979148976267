import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import InputController from '../../../Global/InputController'

import { useHistory, useParams } from 'react-router-dom'

import * as Yup from 'yup'
import {
  CREATE_APP_USER,
  GET_ALL_APP_USER_PAGINATION,
  GET_APP_USER_BY_ID,
  UPDATE_APP_USER,
} from '../../../../graphql/Catalog/AppUsers/AppUser'
import { GET_ALL_USER_ROLES_NO_PAGINATION } from '../../../../graphql/Catalog/AppUsers/AppUserRoles'

const UsersAppNew = () => {
  const { id: _id, show } = useParams()
  const history = useHistory()

  //----------- Hooks --------

  const {
    data: dataOneUser,
    loading: loadingOneUser,
    error: errorOneUser,
  } = useQuery(GET_APP_USER_BY_ID, {
    variables: {
      id: parseInt(_id ? _id : 0),
    },
  })

  const {
    data: dataUserTypes,
    loading: loadingUserTypes,
    error: errorUserTypes,
  } = useQuery(GET_ALL_USER_ROLES_NO_PAGINATION)

  const [loading, setLoading] = useState(false)
  const [userTypes, setUserTypes] = useState([])

  const [createAppUser] = useMutation(CREATE_APP_USER, {
    refetchQueries: [
      {
        query: GET_ALL_APP_USER_PAGINATION,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateAppUser] = useMutation(UPDATE_APP_USER, {
    refetchQueries: [
      {
        query: GET_ALL_APP_USER_PAGINATION,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_APP_USER_BY_ID,
        variables: {
          id: parseInt(_id),
        },
      },
    ],
  })

  //----------- Yup -----------
  const createValidationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    username: Yup.string().required('This field is required'),
    password: Yup.string().required('This field is required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password does not match')
      .required('This field is required'),
    role: Yup.string().required('This field is required'),
    area: Yup.string().required('This field is required'),
  })

  const updateValidationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    username: Yup.string().required('This field is required'),
    password: Yup.string(),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Password does not match'
    ),
    role: Yup.string().required('This field is required'),
    area: Yup.string().required('This field is required'),
  })

  const {
    reset,
    watch,
    methods,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(
      _id ? updateValidationSchema : createValidationSchema
    ),
  })

  //---------- useEffects ---------

  //To set select options of user roles
  useEffect(() => {
    if (!errorUserTypes && !loadingUserTypes) {
      const list = dataUserTypes.getAllUserRolesNoPagination.map((item) => {
        return { value: item.id, label: item.name }
      })
      setUserTypes(list)
    }
  }, [dataUserTypes, loadingUserTypes, errorUserTypes])

  //To get user data by ID
  useEffect(() => {
    if (_id && !loadingOneUser) {
      if (errorOneUser)
        return toastSweetAlert({
          mode: 'error',
          message: errorOneUser.message,
        })

      if (dataOneUser) {
        setValue('name', dataOneUser.getAppUserById.name)
        setValue('username', dataOneUser.getAppUserById.username)
        setValue('area', dataOneUser.getAppUserById.area_id)
        setValue('role', dataOneUser.getAppUserById.role_id)
      }
    }
  }, [dataOneUser, loadingOneUser, errorOneUser])

  //----------- Functions ----------

  //To submit form
  const handleSave = async (Data) => {
    if (show) return
    setLoading(true)

    try {
      const { name, username, password, area, role } = Data
      if (_id)
        await updateAppUser({
          variables: {
            id: parseInt(_id),
            name,
            username,
            password: password || null,
            area_id: parseInt(area),
            role_id: parseInt(role),
          },
        })
      else
        await createAppUser({
          variables: {
            name,
            username,
            password: password || null,
            area_id: parseInt(area),
            role_id: parseInt(role),
          },
        })

      return toastSweetAlert(
        {
          mode: 'ok',
          message: `App user has been ${
            _id ? 'updated' : 'created'
          } successfully`,
        },
        history.goBack()
      )
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        title="App user"
        breadcrumb="App user"
        windowTitle={`${_id ? 'Edit' : 'Add'} app user `}
      />
      <FormProvider {...methods}>
        <form className="p-3" onSubmit={handleSubmit(handleSave)}>
          <Box
            title={`${_id ? 'Edit' : 'Add'} new App user`}
            btnRedPath="/catalog/app/user"
            btnRedTxt="Cancel"
            btnSubmit={true}
            btnState={loading}
            errors={errors}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <InputController
                      label="Name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <InputController
                      label="Username"
                      type="text"
                      name="username"
                      placeholder="Name"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <InputController
                      label="User role"
                      type="number"
                      inputType="choosen"
                      name="role"
                      placeholder="Choose a role"
                      control={control}
                      options={userTypes}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    {/* <InputController
                      label="Area"
                      type="number"
                      inputType="choosen"
                      name="area"
                      placeholder="Choose an area"
                      control={control}
                      options={areas}
                      disabled={show}
                    /> */}
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <InputController
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Ingrese una contraseña"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <InputController
                      label="Confirm password"
                      type="password"
                      name="passwordConfirm"
                      placeholder="Confirme la contraseña"
                      control={control}
                      disabled={show}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default UsersAppNew
