import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Logo from '../../../Assets/Images/vinos-america-logo.png'

const RecordsReportItem = ({
    title = '',
    description = '',
    colors = ['#C3353F', '#FFD186', '#153540', '#D6B287'],
    data = [
        { name: 'option1', y: 10 },
        { name: 'option2', y: 5 },
    ],
    type = 'pie',
    categories = [],
    tooltip = {},
}) => {
  //------- Hooks --------

  const options = {
    title: false,
    colors,
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type,
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    credits: {
        enabled: false
    },
    xAxis: {
      categories,
    },
    tooltip,
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    responsive: {
        rules: [{
          condition: {
            
          },
          chartOptions: {
            chart: {
              height: '330px'
            },
            subtitle: {
              text: null
            },
            navigator: {
              enabled: false
            }
          }
        }]
      },
    series: [{
      name: 'Cantidad',
      colorByPoint: true,
      data,
    }]
  }

  return (
    <div className='row pt-4 pb-4'>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 description'>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 graphic'>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
        <img className='logo-small' src={Logo} alt="alquimia-logo" />
      </div>
  </div>
  )
}

export default RecordsReportItem
