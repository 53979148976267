import { FormProvider, useForm } from 'react-hook-form'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_USER_PERMISSIONS,
  GET_USERS,
  REGISTER_USER,
} from '../../graphql/Catalog/Users/user'
import { useContext, useEffect, useState } from 'react'
import { validationSchemaUser } from '../Helpers/validatorCustomerForm'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '../Global/Box'
import InputController from '../Global/InputController'
import { AUTH_USER, DECRYPT_TOKEN } from '../../graphql/Auth/auth'
import { AuthContext } from '../../Auth/AuthContext'
import { types } from '../../Types/types'
import Logo from './../../Assets/Images/vinos-america-logo.png'
import { GET_AGES } from '../../graphql/Catalog/Age/age'
import Swal from 'sweetalert2'
import { GET_ALL_GENDERS } from '../../graphql/Catalog/Genders/genders'

export const LoginRegister = () => {
  const { dispatch } = useContext(AuthContext)

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  //----------- Hooks to save data ------------
  const [passwordView, setPasswordView] = useState(true)
  const [schemaValidator, setSchemaValidator] = useState(validationSchemaUser)
  const [age, setAge] = useState(0)
  const [ageUser, setAgeUser] = useState()

  const [options,setOptions] = useState({
    genders: [],
  })
  const [genderSelected, setGenderSelected] = useState()


  const [loading, setLoading] = useState(false)
  //------------ Queries and Mutations ------------
  const [authUser] = useMutation(AUTH_USER)
  const [decryptToken] = useMutation(DECRYPT_TOKEN)
  const [getAllUserPermissions] = useMutation(GET_ALL_USER_PERMISSIONS, {
    fetchPolicy: 'no-cache',
  })

  const [registerUser] = useMutation(REGISTER_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const {
    data: dataAge,
    loading: loadingAge,
    error: errorAge,
  } = useQuery(GET_AGES)

  const {
    data: dataGenders,
    loading: loadingGenders,
    error: errorGenders,
  } = useQuery(GET_ALL_GENDERS)

  const {
    reset,
    watch,
    methods,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaValidator),
  })

  // --------- UseEffects -----
  useEffect(() => {
    if (!loadingAge && !loadingGenders) {
      if (errorAge || errorGenders) {
        return toastSweetAlert({
          mode: 'error',
          message: errorAge? errorAge.message: errorGenders.message,
        })
      }

      //Get data
      let list = []
      dataAge?.getAllAges?.map((item) => {
        list.push({
          id: item.id,
          name: item.name,
        })
      })

      let listGenders = []
      dataGenders?.getAllGenders?.rows?.map((item) => {
        listGenders.push({
          id: item.id,
          name: item.name,
        })
      })

      setOptions({
        genders: listGenders,
      })

      setAge(list)
    }
  }, [dataAge, errorAge, loadingAge,dataGenders,errorGenders,loadingGenders])

  useEffect(() => {
    if (errors) {
      const { name, password, userAge, userEmail, userPhone, userGender } = errors
      if (name?.message)
        Toast.fire({
          icon: 'error',
          title: name.message,
        })
      if (password?.message)
        Toast.fire({
          icon: 'error',
          title: password.message,
        })
      if (userAge?.message)
        Toast.fire({
          icon: 'error',
          title: userAge.message,
        })
      if (userEmail?.message)
        Toast.fire({
          icon: 'error',
          title: userEmail.message,
        })
      if (userPhone?.message)
        Toast.fire({
          icon: 'error',
          title: userPhone.message,
        })
      if (userGender?.message)
          Toast.fire({
            icon: 'error',
            title: userGender.message,
          })
    }
  }, [errors])

  //-------- Functions -----------
  const handleSave = async (Data) => {
    setLoading(true)
    try {
      if (Data.password.length != 4) {
        setLoading(false)
        return Toast.fire({
          icon: 'error',
          title: 'La contraseña debe tener 4 numeros',
        })
      }
      if (Data.name.trim().length == 0) {
        setLoading(false)
        return Toast.fire({
          icon: 'error',
          title: 'El nombre es requerido',
        })
      }
      for (const character of Data.password) {
        if (
          character != '0' &&
          character != '1' &&
          character != '2' &&
          character != '3' &&
          character != '4' &&
          character != '5' &&
          character != '6' &&
          character != '7' &&
          character != '8' &&
          character != '9'
        ) {
          setLoading(false)
          return Toast.fire({
            icon: 'error',
            title: 'La contraseña debe contener numeros',
          })
        }
      }

      const user = await registerUser({
        variables: {
          input: {
            name: Data.name.trim(),
            email: Data.userEmail.toLowerCase().trim(),
            password: Data.password.trim(),
            phone: String(Data.userPhone).trim(),
            id_gender: parseInt(Data.userGender),
            id_age: parseInt(Data.userAge),
          },
        },
      })
      if (user) {
        const { data: dataToken } = await authUser({
          variables: {
            input: {
              email: Data.userEmail.toLowerCase().trim(),
              password: Data.password.trim(),
            },
          },
        })
        const token = dataToken.authUser.token
        localStorage.setItem('token', token)
        const { data: tokenData } = await decryptToken({
          variables: {
            token,
          },
        })
        const decoded = tokenData.decryptToken
        const { data: userPermissionsData } = await getAllUserPermissions({
          variables: {
            userID: decoded.id,
          },
        })
        const userPermissions = userPermissionsData.getAllUserPermissions
        dispatch({
          type: types.login,
          payload: {
            logged: true,
            idUser: decoded.id,
            role: decoded.role,
            email: decoded.email,
            userPermissions,
            avatar: decoded.avatar,
            name: decoded.name,
            phone: decoded.phone,
            age: decoded.age,
          },
        })
        return (
          setLoading(false),
          toastSweetAlert({
            mode: 'ok',
            message: 'Se ha registrado con éxito',
          })
        )
      } else {
        setLoading(false)
        return Toast.fire({
          icon: 'error',
          title: 'Surgio un error, intentelo mas tarde',
        })
      }
    } catch (error) {
      setLoading(false)
      return Toast.fire({
        icon: 'error',
        title: error.message,
      })
    }
  }

  const handleChange = (prop, field) => {
    if(field == 'userGender') setGenderSelected(prop)
    else setAgeUser(prop)
    return setValue(field, prop)
  }

  const handleSeePassword = (id) => {
    var pw = document.getElementById(id)
    if (passwordView) pw.classList.remove('implement-password-view')
    else pw.classList.add('implement-password-view')
    return setPasswordView(!passwordView)
  }

  return (
    <>
      {!loading && (
        <div className="full-container-register">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
              <Box
                title={'Regístrate para votar'}
                btnRedPath="/login"
                btnRedTxt="Cancelar"
                btnSubmitText="Registrarte"
                btnSubmit={true}
                btnState={loading}
                errors={errors}
                content={
                  <>
                    <div className="row">
                      <div className="col-12 image-box">
                        <img src={Logo} className="img-logo-full" alt="logo" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-12 col-xs-12 col-md-12 col-lg-6 col-xl-6">
                        <InputController
                          label="Nombre"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Ingrese un nombre"
                          control={control}
                        />
                      </div>
                      <div className="mb-3 col-12 col-xs-12 col-md-12 col-lg-6 col-xl-6">
                        <InputController
                          label="Correo electrónico"
                          type="text"
                          name="userEmail"
                          placeholder="Ingrese un correo electrónico"
                          control={control}
                        />
                      </div>
                      <div className="mb-3 col-12 col-xs-12 col-md-12">
                        <InputController
                          label="Teléfono"
                          type="text"
                          name="userPhone"
                          placeholder="Ingrese un teléfono"
                          control={control}
                          minlength="1"
                          maxlength="10"
                          inputmode="numeric"
                          pattern="\d*"
                        />
                      </div>
                      <div className="mb-3 col-12 col-md-6">
                        <label>
                          <b>Género *</b>
                        </label>
                        <fieldset>
                          {options.genders &&
                            options.genders.map((itemGender, index) => {
                              return (
                                <div>
                                  <input
                                    className='mr-1'
                                    type="radio"
                                    id={itemGender.name + '-radio-' + index}
                                    name={itemGender.name + '-radio-' + index}
                                    value={itemGender.id}
                                    checked={
                                      genderSelected && genderSelected == itemGender.id
                                        ? true
                                        : false
                                    }
                                    onChange={() => handleChange(itemGender.id, 'userGender')}
                                  />
                                  <label
                                    htmlFor={itemGender.name + '-radio-' + index}
                                    className='white-text'
                                  >
                                    {itemGender.name}
                                  </label>
                                </div>
                              )
                            })}
                        </fieldset>
                        {errors?.userGender && errors.userGender.message && (
                          <span className="error">
                            {errors.userGender.message}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 col-12 col-md-6">
                        <label>Rango de edad *</label>
                        <fieldset>
                          {age &&
                            age.map((itemAge, index) => {
                              return (
                                <div>
                                  <input
                                    className='mr-1'
                                    type="radio"
                                    id={itemAge.name + '-radio-' + index}
                                    name={itemAge.name + '-radio-' + index}
                                    value={itemAge.id}
                                    checked={
                                      ageUser && ageUser == itemAge.id
                                        ? true
                                        : false
                                    }
                                    onChange={() => handleChange(itemAge.id, 'userAge')}
                                  />
                                  <label
                                    htmlFor={itemAge.name + '-radio-' + index}
                                  >
                                    {itemAge.name}
                                  </label>
                                </div>
                              )
                            })}
                        </fieldset>
                        {errors?.userAge && errors.userAge.message && (
                          <span className="error">
                            {errors.userAge.message}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 icon-inside">
                        <InputController
                          label="Contraseña (PIN de 4 digitos)"
                          type="text"
                          name="password"
                          placeholder="Contraseña"
                          id="implement-password-view"
                          addClass="implement-password-view"
                          control={control}
                          minlength="4"
                          maxlength="4"
                          inputmode="numeric"
                          pattern="\d*"
                        />
                        <i
                          className="far fa-eye"
                          onClick={() =>
                            handleSeePassword('implement-password-view')
                          }
                        ></i>
                      </div>
                    </div>
                  </>
                }
              />
            </form>
          </FormProvider>
        </div>
      )}
    </>
  )
}
