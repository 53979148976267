import * as Yup from 'yup'

const RfcValidator =
  /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/

const userNameValidator = /^[a-zA-Z0-9]*$/

const contactsValidator = Yup.array().of(
  Yup.object().shape({
    contactName: Yup.string().required('This field is required'),
    paternalSurname: Yup.string().required('This field is required'),
    maternalSurname: Yup.string(),
    phone: Yup.string()
      .min(10, 'El número de teléfono debe ser a 10 dígitos')
      .max(10, 'El número de teléfono debe ser a 10 dígitos')
      .required('This field is required'),
    cell: Yup.string()
      .min(10, 'El número de teléfono debe ser a 10 dígitos')
      .max(10, 'El número de teléfono debe ser a 10 dígitos')
      .required('This field is required'),
    email: Yup.string()
      .email('Email inválido')
      .required('This field is required'),
  })
)

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  RFC: Yup.string()
    .matches(RfcValidator, 'RFC inválido')
    .required('This field is required'),
  socialReason: Yup.string().required('This field is required'),
  direction: Yup.string().required('This field is required'),
  outdoorNumber: Yup.string().required('This field is required'),
  postalCode: Yup.string()
    .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .required('This field is required'),
  colonia: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  municipality: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  contacts: contactsValidator,
})

export const validationSchemaUser = Yup.object().shape({
  name: Yup.string()
    .trim()
    // .strict(true)
    .required('El nombre es requerido'),
  userEmail: Yup.string()
    .trim()
    // .strict(true)
    .email('El correo electrónico es invalido')
    .required('El correo electrónico es requerido'),
  password: Yup.string()
    .trim()
    // .strict(true)
    .min(4, 'La contraseña debe tener al menos 4 numeros')
    .max(4, 'La contraseña debe tener como maximo 4 numeros')
    .required('La contraseña es requerida'),
  userPhone: Yup.string()
    .trim()
    // .strict(true)
    .min(10, 'El número de teléfono debe ser a 10 dígitos')
    .max(10, 'El número de teléfono debe ser a 10 dígitos')
    .required('El teléfono es requerido'),
  userAge: Yup.string().required('La edad es requerida'),
  userGender: Yup.string().required('El genero es requerido'),
})

export const validationSchemaUserUpdate = Yup.object().shape({
  name: Yup.string().trim().required('Este campo es requerido'),
  userEmail: Yup.string()
    .trim()
    .email('Email inválido')
    .required('Este campo es requerido'),
  userPhone: Yup.string()
    .trim()
    .min(10, 'El número de teléfono debe ser a 10 dígitos')
    .max(10, 'El número de teléfono debe ser a 10 dígitos')
    .required('Este campo es requerido'),
  password: Yup.string()
    .trim()
    .min(0, 'La contraseña debe tener al menos 4 numeros')
    .max(4, 'La contraseña debe tener como maximo 4 numeros'),
  userPhone: Yup.string()
    .trim()
    .min(10, 'El número de teléfono debe ser a 10 dígitos')
    .max(10, 'El número de teléfono debe ser a 10 dígitos'),
  userAge: Yup.string().required('Este campo es requerido'),
  userGender: Yup.string().required('El genero es requerido'),
})

export const passwordValidator = Yup.object().shape({
  currentPassword: Yup.string().required('Este campo es requerido'),
  password: Yup.string()
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .required('Necesita confirmar la contraseña')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
})

export const commodityReceiptValidator = Yup.object().shape({
  quantity: Yup.number().required('This field is required'),
})

export const validationSchemaUserAppOperator = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string(),
  userName: Yup.string()
    .matches(
      userNameValidator,
      'El nombre de usuario sólo puede contener letras y números, sin espacios'
    )
    .required('This field is required'),
  userEmail: Yup.string()
    .email('Email inválido')
    .required('This field is required'),
  password: Yup.string()
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
    .required('This field is required'),
  passwordConfirm: Yup.string()
    .required('Necesita confirmar la contraseña')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
  direction: Yup.string().required('This field is required'),
  outdoorNumber: Yup.string().required('This field is required'),
  postalCode: Yup.string()
    .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .required('This field is required'),
  colonia: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  municipality: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  contacts: contactsValidator,
  avatar: Yup.string().required('This field is required'),
  warehouse_id: Yup.string(),
  id_vehicle: Yup.string().required('This field is required'),
  // .notRequired()
  // .when('userRole', {
  //   is: (val) => val === '3',
  //   then: Yup.string().required('This field is required'),
  //   otherwise: Yup.string().notRequired(),
  // }),
})

export const validationSchemaUserAppOperatorUpdate = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string(),
  userEmail: Yup.string()
    .email('Email inválido')
    .required('This field is required'),
  userName: Yup.string()
    .matches(
      userNameValidator,
      'El nombre de usuario sólo puede contener letras y números, sin espacios'
    )
    .required('This field is required'),
  direction: Yup.string().required('This field is required'),
  outdoorNumber: Yup.string().required('This field is required'),
  postalCode: Yup.string()
    .min(4, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .max(6, 'El CP debe ser mayor a 4 y menor a 6 dígitos')
    .required('This field is required'),
  colonia: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  municipality: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  warehouse_id: Yup.string(),
  id_vehicle: Yup.string().required('This field is required'),
  // .notRequired()
  // .when('userRole', {
  //   is: (val) => val === '3',
  //   then: Yup.string().required('This field is required'),
  //   otherwise: Yup.string().notRequired(),
  // }),
  contacts: contactsValidator,
  avatar: Yup.string().required('This field is required'),
})
