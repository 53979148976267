import { FormProvider, useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@apollo/client'

//Customer components
import ContentHeader from "../../../Layout/ContentHeader"
import InputController from '../../../Global/InputController'
import Box from '../../../Global/Box'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
//Graphql schema
import { CREATE_USER_ROLE, GET_ALL_USER_ROLES_PAGINATION, GET_USER_ROLE_BY_ID, UPDATE_USER_ROLE } from '../../../../graphql/Catalog/AppUsers/AppUserRoles'

const AppUserRolesNew =()=>{
    const { id:_id, show } = useParams()
    const history = useHistory()

    //------ Hooks -----
    const [createUserRole] = useMutation(CREATE_USER_ROLE, {
        refetchQueries: [
            {
            query: GET_ALL_USER_ROLES_PAGINATION,
                variables: {
                    limit: 10,
                    offset: 0,
                    searchQuery: null,
                },
            },
        ],
    })
    
    const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
        refetchQueries: [
            {
            query: GET_ALL_USER_ROLES_PAGINATION,
                variables: {
                    limit: 10,
                    offset: 0,
                    searchQuery: null,
                },
            },
            { query: GET_USER_ROLE_BY_ID, variables: { id: parseInt(_id) } },
        ],
    })
    const [loadingBtn, setLoadingBtn] = useState(false)

    //------ Yup schema -----
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
    })

    //------ Yup form -----
    const {
        methods,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
    })

    //To get area by id
    const {
        loading,
        error,
        data
    } = useQuery(GET_USER_ROLE_BY_ID,{
        variables:{
            id: parseInt(_id? _id:0)
        }
    })

    //------ useEffects
    useEffect(()=>{
        if(!loading && _id){
            if(error)
                toastSweetAlert({
                    mode:'error',
                    message:error.message,
                })

            const {name} = data?.getUserRoleById
            const dataFormated = {
                name,
            }
            reset(dataFormated)
        }
    },[loading,error,data])

    //------ Functions -----
    const onSubmit = async (Data) => {
        if(show) return
        setLoadingBtn(true)
        try {
            const {name} = Data

            if(_id)
                await updateUserRole({
                    variables: {
                        id: parseInt(_id),
                        name,
                    },
                })
            else
                await createUserRole({
                    variables: {
                        name,
                    },
                })
            
            setLoadingBtn(false)
            
            return toastSweetAlert({
                mode: 'ok',
                message: `User role has been ${_id ? 'updated' : 'created'} successfully`,
            },history.goBack())
            
        } catch (error) {
            setLoadingBtn(false)
            return toastSweetAlert({
              mode: 'error',
              message: error.message,
            })
        }
    }
    
    return (
        <>
        <ContentHeader
          title="App User role"
          breadcrumb="App User role"
          windowTitle={`${_id ? 'Edit' : 'Add'} app user role `}
        />
        <FormProvider {...methods}>
          <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
            <Box
              title={`${_id ? 'Edit' : 'Add'} new app user role`}
              btnRedPath="/catalog/app/role"
              btnRedTxt="Cancel"
              errors={errors}
              btnSubmit={true}
              btnLoading={loadingBtn}
              content={
                <>
                  <div className="row">
                    <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                      <InputController
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        control={control}
                        disabled={show}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </form>
        </FormProvider>
      </>
    )
}

export default AppUserRolesNew