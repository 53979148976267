import React, { useContext, useEffect, useState } from 'react'
import ContentHeader from '../../../../Components/Layout/ContentHeader'
import Box from '../../../Global/Box'
import { useMutation, useQuery } from '@apollo/client'
import {
  REGISTER_USER,
  GET_USERS,
  UPDATE_USER,
  GET_USER_BY_ID,
} from '../../../../graphql/Catalog/Users/user'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  validationSchemaUser,
  validationSchemaUserUpdate,
} from '../../../Helpers/validatorCustomerForm'
import InputController from '../../../Global/InputController'
import { AuthContext } from '../../../../Auth/AuthContext'
import { GET_AGES } from '../../../../graphql/Catalog/Age/age'
import { GET_ALL_GENDERS } from '../../../../graphql/Catalog/Genders/genders'

const Register = () => {
  const { id: _id, show } = useParams()
  const { user: _user } = useContext(AuthContext)

  //----------- Hooks to save data ------------
  const [schemaValidator, setSchemaValidator] = useState(validationSchemaUser)

  const [loading, setLoading] = useState(false)
  const [age, setAge] = useState([])
  const [ageUser, setAgeUser] = useState()
  const [genderSelected, setGenderSelected] = useState()

  const history = useHistory()
  const [options,setOptions] = useState({
    genders: [],
  })

  //------------ Queries and Mutations ------------
  const [registerUser] = useMutation(REGISTER_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: GET_USERS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_USER_BY_ID,
        variables: {
          getUserByIdId: parseInt(_id),
        },
      },
    ],
  })
  const {
    data: dataOneUser,
    loading: loadingOneUser,
    error: errorOneUser,
  } = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: parseInt(_id),
    },
  })

  const {
    data: dataAge,
    loading: loadingAge,
    error: errorAge,
  } = useQuery(GET_AGES)

  const {
    data: dataGenders,
    loading: loadingGenders,
    error: errorGenders,
  } = useQuery(GET_ALL_GENDERS)

  const {
    reset,
    watch,
    methods,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schemaValidator),
  })

  //----------- UseEffect ------------
  useEffect(() => {
    if (_id) {
      setSchemaValidator(validationSchemaUserUpdate)
      if (!loadingOneUser) {
        if (errorOneUser)
          return toastSweetAlert({
            mode: 'error',
            message: errorOneUser.message,
          })

        if (dataOneUser?.GetUserById) {
          const { name, email, phone, id_age, id_gender } = dataOneUser?.GetUserById
          setValue('name', name)
          setValue('userEmail', email)
          setValue('userPhone', phone)
          setValue('userAge', id_age)
          setValue('userGender', id_gender)
          setAgeUser(id_age)
          setGenderSelected(id_gender)
        }
      }
    }
  }, [_id, dataOneUser, errorOneUser])

  useEffect(() => {
    if (!loadingAge && !loadingGenders) {
      if (errorAge || errorGenders) {
        return toastSweetAlert({
          mode: 'error',
          message: errorAge ? errorAge.message: errorGenders.message,
        })
      }

      //Get data
      let list = []
      dataAge?.getAllAges?.map((item) => {
        list.push({
          id: item.id,
          name: item.name,
        })
      })

      //Set Options
      let listGenders = []
      dataGenders?.getAllGenders?.rows?.map((item) => {
        listGenders.push({
          id: item.id,
          name: item.name,
        })
      })

      setAge(list)
      setOptions({
        genders: listGenders,
      })
    }
  }, [dataAge, errorAge, loadingAge, dataGenders, loadingGenders, errorGenders])

  useEffect(() => {
    if (_id && !show) setSchemaValidator(validationSchemaUserUpdate)
    else setSchemaValidator(validationSchemaUser)
  }, [])

  //-------- Functions -----------
  const handleSave = async (Data) => {
    setLoading(true)
    try {
      if(parseInt(Data.userGender) == 0){
        setLoading(false)
        return toastSweetAlert({
          mode: 'error',
          message: 'Seleccione un genero',
        })
      }

      if (!_id) {
        if (Data.password.length != 4) {
          setLoading(false)
          return toastSweetAlert({
            mode: 'error',
            message: 'La contraseña debe tener 4 numeros',
          })
        }
        for (const character of Data.password) {
          if (
            character != '0' &&
            character != '1' &&
            character != '2' &&
            character != '3' &&
            character != '4' &&
            character != '5' &&
            character != '6' &&
            character != '7' &&
            character != '8' &&
            character != '9'
          ) {
            setLoading(false)
            return toastSweetAlert({
              mode: 'error',
              message: 'La contraseña debe contener numeros',
            })
          }
        }
      }

      setLoading(false)
      if (Data.password.length > 0) {
        const passValidate = Data.password.trim()
        if (passValidate.length != 4) {
          return toastSweetAlert({
            mode: 'error',
            message: 'La contraseña no debe contener espacios',
          })
        }
      }

      if (_id) {
        await updateUser({
          variables: {
            userId: parseInt(_id),
            input: {
              name: Data.name,
              email: Data.userEmail,
              password: Data.password,
              phone: Data.userPhone,
              id_gender: parseInt(Data.userGender),
              id_age: parseInt(Data.userAge),
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Usuario ha sido actualizado con éxito',
          }),
          history.push(`/catalog/users`)
        )
      } else {
        await registerUser({
          variables: {
            input: {
              name: Data.name,
              email: Data.userEmail,
              password: Data.password,
              phone: String(Data.userPhone),
              id_gender: parseInt(Data.userGender),
              id_age: parseInt(Data.userAge),
            },
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'El usuario ha sido registrado correctamente',
          },
          history.push(`/catalog/users`)
        )
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleChange = (prop, field) => {
    if(field == 'userGender') setGenderSelected(prop)
    else setAgeUser(prop)
    return setValue(field, prop)
  }

  return (
    <>
      <ContentHeader
        title="Control de usuario"
        breadcrumb="Usuarios"
        windowTitle={`${_id ? 'Editar' : 'Agregar nuevo'} usuario `}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(handleSave)}>
          <Box
            title={`${_id ? 'Editar' : 'Agregar nuevo'} usuario`}
            btnRedPath="/catalog/users"
            btnRedTxt="Cancelar"
            btnSubmit={!show}
            btnState={loading}
            errors={errors}
            content={
              <>
                <div className="mb-3 col-12">
                  <h2>Informacion general</h2>
                </div>
                <div className="mb-3 col-12">
                  <hr />
                </div>
                <div className="row">
                  <div className="mb-3 col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <InputController
                      label="Nombre"
                      type="text"
                      name="name"
                      placeholder="Ingrese un nombre"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <InputController
                      label="Correo electrónico"
                      type="text"
                      name="userEmail"
                      placeholder="Ingrese un correo electrónico"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <InputController
                      label="Teléfono"
                      type="number"
                      name="userPhone"
                      placeholder="Ingrese un teléfono"
                      control={control}
                      disabled={show}
                    />
                  </div>
                  <div className="mb-3 col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <label>Rango de edad *</label>
                    <fieldset>
                      {age &&
                        age.map((itemAge, index) => {
                          return (
                            <div>
                              <input
                                className='mr-1'
                                type="radio"
                                id={itemAge.name + '-radio-' + index}
                                name={itemAge.name + '-radio-' + index}
                                value={itemAge.id}
                                checked={
                                  ageUser && ageUser == itemAge.id
                                    ? true
                                    : false
                                }
                                onChange={() => handleChange(itemAge.id, 'userAge')}
                                disabled={show}
                              />
                              <label htmlFor={itemAge.name + '-radio-' + index}>
                                {itemAge.name}
                              </label>
                            </div>
                          )
                        })}
                    </fieldset>
                    {errors?.userAge && errors.userAge.message && (
                      <span className="error">{errors.userAge.message}</span>
                    )}
                  </div>
                  <div className="mb-3 col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <label>
                      <b>Género *</b>
                    </label>
                    <fieldset>
                      {options.genders &&
                        options.genders.map((itemGender, index) => {
                          return (
                            <div>
                              <input
                                className='mr-1'
                                type="radio"
                                id={itemGender.name + '-radio-' + index}
                                name={itemGender.name + '-radio-' + index}
                                value={itemGender.id}
                                disabled={show}
                                checked={
                                  genderSelected &&
                                  genderSelected == itemGender.id
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleChange(itemGender.id, 'userGender')
                                }
                              />
                              <label
                                htmlFor={itemGender.name + '-radio-' + index}
                                className="white-text"
                              >
                                {itemGender.name}
                              </label>
                            </div>
                          )
                        })}
                    </fieldset>
                    {errors?.userGender && errors.userGender.message && (
                      <span className="error">{errors.userGender.message}</span>
                    )}
                  </div>
                  {!show && (
                    <>
                      <div className="mb-3 col-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <InputController
                          label="Contraseña (PIN de 4 digitos)"
                          type="number"
                          name="password"
                          placeholder="Contraseña"
                          addClass="implement-password-view"
                          control={control}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default Register
