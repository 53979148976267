import { useQuery } from '@apollo/client'
import LayoutTable from '../../Global/LayoutTable'
import ContentHeader from '../../Layout/ContentHeader'
import { GET_DRINKS_AVARAGE } from '../../../graphql/Catalog/Drinks/drinks'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useEffect, useState } from 'react'
import VoteItem from './VoteItem'
import VoteTable from './VoteTable'

const Votes = () => {
  //------- Hooks --------
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  //To save all area data
  const [list, setList] = useState([])
  const [headers, setHeaders] = useState([])
  //To save total register
  const [total, setTotal] = useState(0)

  const { loading, data, error } = useQuery(GET_DRINKS_AVARAGE, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
    pollInterval: 30000,//Each 30 seconds
  })

  //------- UseEffects --------
  useEffect(() => {
    if (!loading) {
      // if (error)
      //   return toastSweetAlert({
      //     mode: 'error',
      //     message: error.message,
      //   })

      let list = []
      data?.getDrinksAvarage?.rows.map((item, index) => {
        list.push({
          // id: item.id,
          trago: (
            <VoteItem
              title={item?.drink.drink_name}
              src={item?.drink?.image?.url}
              key={index}
              displayTitle={false}
            />
          ),
          bar: (
            <VoteItem
              title={item?.drink?.bar_name}
              src={item?.drink?.image_bar?.url}
              key={index}
              displayTitle={false}
            />
          ),
          'creatividad y sabor':
            item?.avarage == 0 ? 0 : parseFloat(item?.avarage).toFixed(2),
          'tragos servidos': item?.drinks_served,
        })
      })
      setList(list)
      setHeaders(['trago', 'bar', 'creatividad y sabor', 'tragos servidos'])
      setTotal(data?.getDrinksAvarage?.count)
    }
  }, [loading, data, error])

  return (
      <VoteTable
        data={list}
        headers={headers}
        total={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
      />
  )
}

export default Votes
