import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { DELETE_USER_ROLE, GET_ALL_USER_ROLES_PAGINATION } from '../../../../graphql/Catalog/AppUsers/AppUserRoles'

import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'

const AppUserRoles =()=>{
    //------- Hooks --------
    const [pagePagination, setPagePagination] = useState({
        searchQuery: null,
        limit: 10,
        offset: 0,
    })

    //To save all area data
    const [list,setList] = useState([])
    //To save total register
    const [total, setTotal] = useState(0)

    const {
        loading,
        data,
        error
    } = useQuery(GET_ALL_USER_ROLES_PAGINATION,{
        variables: {
            searchQuery: pagePagination.searchQuery,
            limit: pagePagination.limit,
            offset: pagePagination.offset,
        }
    })

    //------- UseEffects --------
    useEffect(() => {
        if(!loading){
            if(error)
                toastSweetAlert({
                    mode:'error',
                    message:error.message,
                })

            let list = []
            data?.getAllUserRoles?.rows.map((item)=>{
                list.push({
                    id: item.id,
                    name: item.name,
                })
            })
            setList(list)
            setTotal(data?.getAllUserRoles?.count)
        }
    }, [loading,data,error])
  
    return (
        <>
        <ContentHeader
          title="App user roles"
          breadcrumb="App user roles"
          windowTitle="App user roles"
        />
        <LayoutTable
          exportImportButton={false}
          data={list}
          title="App user roles"
          gql={DELETE_USER_ROLE}
          requery={GET_ALL_USER_ROLES_PAGINATION}
          // exportQuery={EXPORT_ROLES}
          totalCount={total}
          pagePagination={pagePagination}
          setPagePagination={setPagePagination}
          loadingData={loading}
          FilterBarPlaceholder= "Search by Name"
          // hideFilterBar
        />
      </>
    )
}

export default AppUserRoles