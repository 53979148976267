import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query getUsers($searchQuery: String, $limit: Int, $offset: Int) {
    Users(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
        email
        phone
        id_role
        id_gender
      }
      count
    }
  }
`

export const GET_ALL_CUSTOMERS_NOT_MINE = gql`
  query GetAllCustomersNotMine {
    getAllCustomersNotMine {
      id
      name
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($getUserByIdId: Int!) {
    GetUserById(id: $getUserByIdId) {
      id
      name
      email
      phone
      id_age
      id_role
      id_gender
      id_user_register
    }
  }
`

export const EXPORT_USERS = gql`
  mutation GetAllUsersExport {
    getAllUsersExport {
      id
      name
      email
      phone
      id_age
      id_role
      id_user_register
    }
  }
`

export const GET_USER = gql`
  mutation getOneUser($userId: Int!) {
    getUser(userID: $userId) {
      name
    }
  }
`
export const GET_ALL_USER_PERMISSIONS = gql`
  mutation getAllUserPermissions($userID: Int!) {
    getAllUserPermissions(userID: $userID) {
      id
      id_module
      module_info {
        name
        relative_link
        icon
        front_label
      }
      submodule_info {
        name
        relative_link
        icon
        front_label
      }
      id_submodule
      access_retrieve
      access_read
      access_edit
      access_delete
      access_export
      access_import
    }
  }
`

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $input: userRegisterInput!
  ) {
    registerUser(
      input: $input
    ) {
      id
      email
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: Int!
    $input: userRegisterInput!
  ) {
    updateUser(
      userID: $userId
      input: $input
    )
  }
`

export const DELETE_USER = gql`
  mutation DeleteUserMutation($id: Int!, $userId: Int!) {
    deleteUser(id: $id, userId: $userId)
  }
`

export const SEND_PASSWORD_TO_EMAIL = gql`
  mutation SendPasswordToEmail($email: String!) {
    sendPasswordToEmail(email: $email) 
  }
`

export const UPDATE_PASSWORD = gql`
  mutation RecoveryUserPassword($inputRecovery: passwordRecoveryInput!) {
    recoveryUserPassword(inputRecovery: $inputRecovery)
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation PasswordUpdate(
    $idUser: Int!
    $currentPassword: String!
    $password: String!
  ) {
    passwordUpdate(
      id_user: $idUser
      currentPassword: $currentPassword
      password: $password
    )
  }
`

export const CHECK_TOKEN_RECOVERY = gql`
  mutation checkTokenRecovery($token: String!) {
    checkTokenRecovery(token: $token)
  }
`
export const GET_ALL_USER_BY_ROL = gql`
query GetUserByIdRol($getUserByIdRolId: Int!) {
  GetUserByIdRol(id: $getUserByIdRolId) {
    id
    name
    id_store
  }
}
`

export const EXPORTS_USERS = gql`
  mutation ExportUsers {
    exportUsers {
      data {
        id
        age
        email
        name
        phone
        sex
        date
      }
      headers {
        key
        label
      }
    }
  }
`