import { gql } from '@apollo/client'

export const GET_ALL_APP_USER_PAGINATION = gql`
  query GetAllAppUsersPagination($searchQuery: String, $limit: Int, $offset: Int) {
    getAllAppUsersPagination(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
        username
        area_id
        role_id
      }
      count 
    }
  }
`

export const GET_APP_USER_BY_ID = gql`
  query GetAppUserById($id: Int!) {
    getAppUserById(id: $id) {
      id
      name
      username
      area_id
      role_id
    }
  }
`

export const DELETE_APP_USER = gql`
  mutation DeleteAppUser($id: Int!) {
    deleteAppUser(id: $id)
  }
`

export const CREATE_APP_USER = gql`
  mutation CreateAppUser($name: String!, $username: String!, $password: String!, $area_id: Int!, $role_id: Int!) {
    createAppUser(name: $name, username: $username, password: $password, area_id: $area_id, role_id: $role_id)
  }
`

export const UPDATE_APP_USER = gql`
  mutation UpdateAppUser($id: Int!, $name: String!, $username: String!, $password: String, $area_id: Int!, $role_id: Int!) {
    updateAppUser(id: $id, name: $name, username: $username, password: $password, area_id: $area_id, role_id: $role_id)
  }
`