import { gql } from '@apollo/client'

export const GET_RECORDS_REPORT = gql`
query GetRecordsReport {
    getRecordsReport {
        global {
            ages {
                name
                y
            }
            genders {
                name
                y
            }
            totalUsers
        }
        friday {
            totalUsers
            genders {
                name
                y
            }
            ages {
                name
                y
            }
            femaleAges {
                name
                y
            }
            maleAges {
                name
                y
            }
        }
        saturday {
            totalUsers
            genders {
                name
                y
            }
            ages {
                name
                y
            }
            femaleAges {
                name
                y
            }
            maleAges {
                name
                y
            }
        }
    }
}
`