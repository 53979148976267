import { useEffect, useState } from 'react'
import Box from '../../Global/Box'
import { Rating } from 'react-simple-star-rating'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_CALIFICATIONS_TYPES,
  GET_DRINK_CALIFICATION_BY_DRINK_ID,
} from '../../../graphql/Catalog/CalificationsTypes/calificationsTypes'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import { useHistory } from 'react-router-dom'
import { CALIFICATE_DRINK } from '../../../graphql/Catalog/Drinks/drinks'
import AlquimiaStar from './estrella-blanca-01.png'
import AlquimiaStarEye from './ojo-estrella-01.png'
import { useParams } from 'react-router-dom'

const DrinksVotes = ({
  drinkId = 0,
  title = '',
  drinkName = '',
  barName = '',
  img = '',
  imgBar = '',
}) => {
  const history = useHistory()
  const { show } = useParams()
  //---------Hooks to save data --------
  const [loadingBtn, setLoadingBtn] = useState(true)
  const [rating, setRating] = useState({})
  const [calificationsTypes, setCalificationsTypes] = useState([])
  const [drinkRating, setDrinkRating] = useState()
  const [votesExpired, setVotesExpired] = useState(false)
  const [thanksView, setThanksView] = useState(false)
  const [load, setLoad] = useState(true)

  //-------- Queries and Mutations ----------
  const { data, loading, error } = useQuery(GET_CALIFICATIONS_TYPES)
  const {
    data: dataDrinkCalification,
    loading: loadingDrinkCalification,
    error: errorDrinkCalification,
  } = useQuery(GET_DRINK_CALIFICATION_BY_DRINK_ID, {
    variables: {
      drinkId,
    },
  })

  const [CalificateDrink] = useMutation(CALIFICATE_DRINK, {
    refetchQueries: [
      {
        query: GET_DRINK_CALIFICATION_BY_DRINK_ID,
        variables: {
          drinkId,
        },
      },
    ],
  })

  //-------- UseEffects --------
  useEffect(() => {
    if (drinkRating && drinkRating.length > 0) setThanksView(true)
    return setLoad(false)
  }, [drinkRating])

  useEffect(() => {
    if (!loading && !loadingDrinkCalification) {
      if (error || errorDrinkCalification) {
        setLoadingBtn(false)
        return toastSweetAlert(
          {
            mode: 'error',
            message: error ? error : errorDrinkCalification,
          },
          history.push('/dashboard')
        )
      }

      //Get califications types
      let listCalifications = []
      data?.getCalificationsTypes?.map((item) => {
        listCalifications.push({
          id: item.id,
          name: item.name,
        })
      })
      setCalificationsTypes(listCalifications)

      setVotesExpired(
        dataDrinkCalification?.getDrinkCalificationByDrinkId?.voteExpired
      )

      //Get califications
      if (dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks) {
        let listRating = []
        //Set values for input
        if (
          dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks[0] &&
          dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks[1] &&
          show == 'edit'
        ) {
          setRating({
            Sabor:
              dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks[0]
                ?.id_calification_type == 1 //Flavor
                ? dataDrinkCalification?.getDrinkCalificationByDrinkId
                  ?.drinks[0].calification
                : dataDrinkCalification?.getDrinkCalificationByDrinkId
                  ?.drinks[1].calification,
            //Creativity
            Creatividad:
              dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks[1]
                ?.id_calification_type == 2
                ? dataDrinkCalification?.getDrinkCalificationByDrinkId
                  ?.drinks[1]?.calification
                : dataDrinkCalification?.getDrinkCalificationByDrinkId
                  ?.drinks[0].calification,
          })
        }

        dataDrinkCalification?.getDrinkCalificationByDrinkId?.drinks.map(
          (item) => {
            listRating.push({
              type: item.id_calification_type,
              rate: item.calification,
            })
          }
        )

        setDrinkRating(listRating)
      }
      setLoadingBtn(false)
    }
  }, [
    data,
    loading,
    error,
    dataDrinkCalification,
    loadingDrinkCalification,
    errorDrinkCalification,
  ])

  //--------- Functions ------
  const handleRating = (rate, field) => {
    setRating({
      ...rating,
      [field]: rate,
    })
  }

  // const handleSendToEdit = () => history.push(`/drinks/details/${drinkId}/edit`)

  const handleSubmit = async () => {
    try {
      //Validate if submit all rating
      let listRates = []
      for (const calificationType of calificationsTypes) {
        if (!rating[calificationType.name])
          return toastSweetAlert({
            mode: 'error',
            message: 'Debe calificar por completo la bebida',
          })
        else
          listRates.push({
            id: calificationType.id,
            rate: rating[calificationType.name],
          })
      }
      const res = await CalificateDrink({
        variables: {
          drinkId,
          input: listRates,
        },
      })
      if (!res)
        return toastSweetAlert({
          mode: 'error',
          message: 'Surgio un error al querer votar, intentelo mas tarde',
        })
      else return setThanksView(true)
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error,
      })
    }
  }

  return (
    <>
      {!load && (
        <div className="drink-vote-box bg-green">
          {votesExpired ? (
            <div className="col-12 pl-3 pr-3 mt-5 mb-2 text-center message-success-two">
              <h2 className="message-success votes-finished">Las votaciones se terminaron</h2>
            </div>
          ) : (
            <>
              {thanksView ? (
                <div className="col-12 mt-5 mb-2 text-center message-success-two">
                  <h2 className="message-success">¡Gracias por tu voto!</h2>
                </div>
              ) : (
                <Box
                  className="bg-green"
                  title={`Votar bebida`}
                  btnRedPath="/drinks"
                  // btnRedTxt={"Cancelar"}
                  btnLoading={loadingBtn}
                  content={
                    <>
                      <div className="drink-vote-container row bg-green">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div >
                            <img src='/HomeView/trago_logo.png' className='drink-logo mx-auto d-block' alt="alquimia-drink-logo" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 branch-image-box">
                          {img && (
                            <>
                              {imgBar ? (
                                <div className="image-boxe image-box-two">
                                  <div className="branch-images-two-columns ">
                                    <img src={img} alt="branch-image" />
                                  </div>
                                  <div className="branch-images-two-columns">
                                    <img src={imgBar} alt="branch-image" />
                                  </div>
                                </div>
                              ) : (
                                <div className="branch-image">
                                  <img src={img} alt="branch-image" />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-12 drink-details">
                          {drinkName && (
                            <>
                              <h2 className='votes-title mt-1'>TRAGO</h2>
                              <p className='votes-description'>{drinkName}</p>
                            </>
                          )}
                          <h2 className='votes-title'>DESTILADO</h2>
                          <p className='votes-description'>{title}</p>

                          <h2 className='votes-title'>BAR</h2>
                          <p className='votes-description'>{barName}</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="votes-aquimia-star mx-auto d-block">
                            <img src='/HomeView/drink_votes_star.png' alt="alquimia-eye" />
                          </div>
                        </div>
                        {calificationsTypes &&
                          calificationsTypes.map((calificationType) => {
                            return (
                              <div className="col-12 drink-calification mt-1 mb-1">
                                <h2 className='votes-title'>{calificationType?.name}</h2>
                                {drinkRating && drinkRating.length > 0 ? (
                                  <>
                                    {drinkRating.map((item) => {
                                      if (item.type == calificationType.id)
                                        return (
                                          <Rating
                                            className='votes-rating'
                                            onClick={(e) =>
                                              handleRating(
                                                e,
                                                calificationType.name
                                              )
                                            }
                                            initialValue={item.rate}
                                            readonly={true}
                                          />
                                        )
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <Rating
                                      onClick={(e) =>
                                        handleRating(e, calificationType.name)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            )
                          })}

                        {drinkRating && drinkRating.length == 0 ? (
                          <div className="col-12 drink-button mt-3">
                            <button
                              className="btn cursor-pointer"
                              onClick={handleSubmit}
                            >
                              Enviar
                            </button>
                          </div>
                        ) : (
                          drinkRating &&
                          drinkRating.length > 0 && (
                            <>
                              <div className="col-12 mt-5 mb-2 text-center">
                                <h2 className="message-success">
                                  ¡Gracias por tu voto!
                                </h2>
                              </div>
                              {/* <div className="col-12 drink-button mt-5 mb-2">
            <button
              className="btn btn-accept"
              onClick={handleSendToEdit}
            >
              Volver a votar
            </button>
          </div> */}
                            </>
                          )
                        )}
                      </div>
                    </>
                  }
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default DrinksVotes
