import { PrivateRoute } from '../Routers/PrivateRoute'
import React from 'react'
import Users from '../Components/Admin/Catalogs/Users/List'
import UsersNew from '../Components/Admin/Catalogs/Users/Register'
import UsersPermissions from '../Components/Admin/Catalogs/Users/Permissions'
import Roles from '../Components/Admin/Catalogs/Roles/Roles'
import RolesNew from '../Components/Admin/Catalogs/Roles/RolesNew'
import Modules from '../Components/Admin/Catalogs/Modules/Modules'
import ModulesNew from '../Components/Admin/Catalogs/Modules/ModulesNew'
import UsersApp from '../Components/Admin/Catalogs/AppUsers/AppUsers'
import UsersAppNew from '../Components/Admin/Catalogs/AppUsers/AppUsersNew'
// import { UserProfileMain } from '../Components/Admin/Catalogs/Users/UserProfile/UserProfileMain'
import Drinks from '../Components/Catalogs/Drinks/Drinks'
import DrinksNew from '../Components/Catalogs/Drinks/DrinksNew'
import Votes from '../Components/Catalogs/Votes/Votes'
import RecordsReport from '../Components/Documents/RecordsReport/RecordsReport'

const moduleRoutesGenerator = (
  read,
  create,
  edit,
  imports,
  module,
  isAuth,
  client
) => {
  const components = {
    Users,
    UsersNew,
    UsersPermissions,
    Roles,
    RolesNew,
    Modules,
    ModulesNew,
    // UserProfileMain,
    UsersApp,
    UsersAppNew,
    Drinks,
    DrinksNew,
    Votes,
    RecordsReport,
  }

  const routesArray = []
  if (read) {
    if (module?.module_info?.name === 'Drinks') {
      if (!client)
        routesArray.push(
          <PrivateRoute
            exact
            key={module.id}
            path={module?.module_info?.relative_link}
            component={components[module?.module_info?.name]}
            isAuth={isAuth}
          />
        )
    } else {
      routesArray.push(
        <PrivateRoute
          exact
          key={module.id}
          path={module?.module_info?.relative_link}
          component={components[module?.module_info?.name]}
          isAuth={isAuth}
        />
      )
    }
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module?.module_info?.relative_link}/details/:id/:show`}
        component={components[`${module?.module_info?.name}New`]}
        isAuth={isAuth}
      />
    )
    if (module?.module_info?.name === 'ControlDeskRoutes') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module?.module_info?.relative_link}/specificDetails/:id/:type`}
          component={components[`${module?.module_info?.name}Details`]}
          isAuth={isAuth}
        />
      )
    }
  }
  if (create) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module?.module_info?.relative_link}/new`}
        component={components[`${module?.module_info?.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (edit) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module?.module_info?.relative_link}/edit/:id`}
        component={components[`${module?.module_info?.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (imports) {
    routesArray.push(
      <PrivateRoute
        exact
        key={module.id}
        path={`${module?.module_info?.relative_link}/import/:form`}
        component={components[`${module?.module_info?.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  return routesArray
}
export default moduleRoutesGenerator
