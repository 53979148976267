import React, { useEffect, useState } from 'react'
import ContentHeader from '../../../Layout/ContentHeader'
import Box from '../../../Global/Box'
import { useMutation, useQuery } from '@apollo/client'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputController from '../../../Global/InputController'
import DynamicForm from '../../../Global/DynamicForm'
import {
  GET_ONE_MODULE,
  CREATE_MODULE,
  UPDATE_MODULE,
  ALL_MODULES,
} from '../../../../graphql/Catalog/Modules/modules'
import * as Yup from 'yup'

export const ModulesNew = () => {
  const { id: _id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [icon, setIcon] = useState('')
  const [idsToDelete, setIdsToDelete] = useState([])
  const [iconSubmodule, setIconSubmodule] = useState('')

  const { data: dataModule } = useQuery(GET_ONE_MODULE, {
    variables: {
      getOneModuleId: parseInt(_id),
    },
  })

  const [createModule] = useMutation(CREATE_MODULE, {
    refetchQueries: [
      {
        query: ALL_MODULES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateModule] = useMutation(UPDATE_MODULE, {
    refetchQueries: [
      {
        query: ALL_MODULES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_ONE_MODULE,
        variables: { getOneModuleId: parseInt(_id) },
      },
    ],
  })

  const validationSchema = Yup.object().shape({
    frontLabel: Yup.string().required('This field is required'),
    moduleName: Yup.string().required('This field is required'),
    relativeLink: Yup.string().required('This field is required'),
    icon: Yup.string().required('This field is required'),
    submodules: Yup.array().of(
      Yup.object().shape({
        submoduleName: Yup.string().required('This field is required'),
        frontLabelSubmodule: Yup.string().required('This field is required'),
        relativeLinkSubmodule: Yup.string().required(
          'This field is required'
        ),
        iconSubmodule: Yup.string().required('This field is required'),
      })
    ),
  })

  const {
    methods,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) })

  useEffect(() => {
    if (_id) {
      if (dataModule !== undefined && dataModule !== null) {
        const data = dataModule.getOneModule
        setIcon(`fas fa-${data.icon}`)
        const submodules = data.submodules.map((submodule, idx) => {
          setIconSubmodule({
            ...iconSubmodule,
            [`submodules[${idx}].iconSubmodule`]: `fas fa-${submodule.icon}`,
          })
          return {
            id: submodule.id,
            submoduleName: submodule.name,
            relativeLinkSubmodule: submodule.relative_link,
            frontLabelSubmodule: submodule.front_label,
            iconSubmodule: submodule.icon,
          }
        })
        const dataFormated = {
          moduleName: data.name,
          frontLabel: data.front_label,
          relativeLink: data.relative_link,
          icon: data.icon,
          submodules: submodules,
        }
        reset(dataFormated)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModule, reset, _id])

  const onSubmit = async (data) => {
    // setLoading(true)
    const submodules = data.submodules.map((submodule) => {
      return {
        id: typeof submodule.id === 'number' ? parseInt(submodule.id) : 0,
        name: submodule.submoduleName,
        relative_link: submodule.relativeLinkSubmodule,
        front_label: submodule.frontLabelSubmodule,
        icon: submodule.iconSubmodule,
      }
    })

    try {
      if (_id) {
        await updateModule({
          variables: {
            moduleId: parseInt(_id),
            moduleInput: {
              name: data.moduleName,
              relative_link: data.relativeLink,
              front_label: data.frontLabel,
              icon: data.icon,
            },
            submodulesIdsTodelete: idsToDelete,
            submoduleInput: submodules,
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Module has been updated successfully',
          },
          history.push(`/modules`)
        )
      } else {
        await createModule({
          variables: {
            moduleInput: {
              name: data.moduleName,
              relative_link: data.relativeLink,
              front_label: data.frontLabel,
              icon: data.icon,
            },
            submoduleInput: submodules,
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Module has been created successfully',
          },
          history.push(`/modules`)
        )
      }
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode:'error',
        message:error.message,
      })
    }
  }

  return (
    <>
      <ContentHeader
        title="Modules Management"
        breadcrumb="Modules"
        windowTitle={`${_id ? 'Edit' : 'Add'} module `}
      />
      <FormProvider {...methods}>
        <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Edit' : 'Add'} module`}
            btnRedPath="/modules"
            btnRedTxt="Cancel"
            btnSubmit={true}
            btnState={loading}
            errors={errors}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-12">
                    <h2>Main module</h2>
                  </div>
                  <div className="mb-3 col-12">
                    <hr />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Etiquette"
                      type="text"
                      name="frontLabel"
                      placeholder="Insert the module name in menu"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Module name"
                      type="text"
                      name="moduleName"
                      placeholder="Insert module name"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <InputController
                      label="Link"
                      type="text"
                      name="relativeLink"
                      placeholder="Insert module link"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-md-12 col-lg-3">
                    <label>Icon</label>
                    <div className="input-group">
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          id="inputGroupAppend"
                        >
                          <i className={icon}></i>
                        </span>
                      </div>
                      <InputController
                        type="text"
                        name="icon"
                        placeholder="FontAwesome icon"
                        aria-describedby="inputGroupAppend"
                        control={control}
                        changeAction={(e) =>
                          setIcon(`fas fa-${e.target.value}`)
                        }
                      />
                    </div>
                  </div>
                  <div className="mb-3 mt-3 col-12">
                    <h2>SubModule</h2>
                  </div>
                  <div className="mb-3 col-12">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <DynamicForm
                    control={control}
                    btnAppendTxt={'Add submodule'}
                    emptyText="There are not submodules"
                    iconSubmodule={iconSubmodule} //PARA MI MÓDULO CUSTOM, NO ES NECESARIO
                    prefix="submodules"
                    idsToDelete={idsToDelete}
                    setIdsToDelete={setIdsToDelete}
                    inputs={[
                      {
                        name: 'id',
                        type: 'hidden',
                      },
                      {
                        name: 'frontLabelSubmodule',
                        label: 'Etiquette',
                        type: 'text',
                        placeholder: 'Insert the module name in menu',
                        colSize: 3,
                      },
                      {
                        name: 'submoduleName',
                        label: 'Submodule name',
                        type: 'text',
                        placeholder: 'Insert submodule name',
                        colSize: 3,
                      },
                      {
                        name: 'relativeLinkSubmodule',
                        label: 'Link',
                        type: 'text',
                        placeholder: 'Insert submodule link',
                        colSize: 3,
                      },
                      {
                        name: 'iconSubmodule',
                        label: 'Submodule icon',
                        type: 'text',
                        placeholder:
                          'FontAwesome icon',
                        iconState: setIconSubmodule, //PARA MI MÓDULO CUSTOM
                        specialSubmodule: true, //PARA MI MÓDULO CUSTOM
                        colSize: 3,
                      },
                    ]}
                  />
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}

export default ModulesNew
