import { gql } from '@apollo/client'

export const GET_AGES = gql`
  query GetAllAges($searchQuery: String, $limit: Int, $offset: Int) {
    getAllAges(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      id
      name
    }
  }
`
