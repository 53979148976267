import { useMutation, useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import LayoutTable from '../../Global/LayoutTable'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import ContentHeader from '../../Layout/ContentHeader'
import {
  DELETE_DRINK,
  EXPORTS_DRINKS,
  GET_DRINKS,
} from '../../../graphql/Catalog/Drinks/drinks'
import { AuthContext } from '../../../Auth/AuthContext'
import { APP_CONFIG, CHANGE_VOTES_SWITCH } from '../../../graphql/Auth/auth'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { CSVLink } from 'react-csv'
import { EXPORTS_USERS } from '../../../graphql/Catalog/Users/user'

const Drinks = () => {
  const MySwal = withReactContent(Swal)

  const { user: _user } = useContext(AuthContext)
  //------- Hooks --------
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  //To save all area data
  const [list, setList] = useState([])
  //To save total register
  const [total, setTotal] = useState(0)
  //To save VotesExpired
  const [votesSwitch,setVotesSwitch] = useState(false)
  //CSV exports
  const [dataExported, setDataExported] = useState({
    users: {headers: [],data: []},
    drinks: {headers: [],data: []},
  })
  const [loadingExport,setLoadingExport] = useState({
    exportUsers: false,
    exportDrinks: false,
  })


  const { loading, data, error } = useQuery(GET_DRINKS, {
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    },
  })

  const {
    data:dataConfig,
    loading:loadingConfig,
    error:errorConfig
  } = useQuery(APP_CONFIG)

  const [ChangeVoteSwitch] = useMutation(CHANGE_VOTES_SWITCH)
  const [ExportsUsers] = useMutation(EXPORTS_USERS)
  const [ExportsDrinks] = useMutation(EXPORTS_DRINKS)


  //------- UseEffects --------
  useEffect(() => {
    if (!loading && !loadingConfig) {
      if (error || errorConfig)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        })

      let list = []
      data?.getDrinks?.rows.map((item) => {
        list.push({
          id: item.id,
          titulo: item.title,
          bar: item.bar_name,
          bebida: item.drink_name,
        })
      })

      setVotesSwitch(dataConfig?.getConfigSys?.votes_switch)
      setList(list)
      setTotal(data?.getDrinks?.count)
    }
  }, [loading, data, error,dataConfig,loadingConfig,errorConfig])

  //-------- Functions ---------
  const onChange =async(switchValue)=> {
    MySwal.fire({
      showCloseButton: true,
      icon: 'warning',
      title: `${switchValue ? 'Activar': 'Desactivar'} votos`,
      html: (
        <>
          <div className="message-container">{`¿Estas seguro de ${switchValue ? 'activar': 'desactivar'} los votos?`}</div>
        </>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await ChangeVoteSwitch({
            variables:{
              voteSwitch: switchValue ? 1: 0,
            },
            refetchQueries: [
              {
                query: APP_CONFIG,
              }
            ]
          })
          return toastSweetAlert({
            mode: 'ok',
            message: `Se han ${switchValue ? 'activado': 'desactivado'} los votos con exito`,
          })
        } catch (error) {
          return toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  const handleCsv = async (type = 'exportUsers')=> {
    try {
      let res
      setLoadingExport({
        ...loadingExport,
        [type]: true,
      })
      if(type == 'exportUsers'){
        res = await ExportsUsers()
        const {data,headers} = res?.data?.exportUsers

        setDataExported({
          ...dataExported,
          users: {
            headers,
            data,
          },
        })
      }else{
        res = await ExportsDrinks()
        const {data,headers} = res?.data?.exportDrinks
        setDataExported({
          ...dataExported,
          drinks: {
            headers,
            data,
          },
        })
      }

      setLoadingExport({
        ...loadingExport,
        [type]: false,
      })
      document.getElementById(type).click()

      return toastSweetAlert({
        mode: 'ok',
        message: 'CSV Generado',
      })
    } catch (error) {
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <>
    {!loading && !loadingConfig && (
      <>
        <ContentHeader
          title="Apagador de votos"
        />
        <label class="switchVotes ml-3">
          <input 
            type="checkbox"  
            checked={votesSwitch} 
            onChange={()=>onChange(!votesSwitch)} 
          />
          <span class="slider"></span>
        </label>
        <ContentHeader
          title="Generar documentos"
        />
        <>
          <CSVLink
            id="exportUsers"
            filename={'Usuarios Alquimia'}
            data={dataExported.users.data} 
            headers={dataExported.users.headers}
            className="hide"
          ></CSVLink>
          <button
            className="btn btn-accept btn-sm ml-3"
            onClick={async()=>await handleCsv('exportUsers')}
            disabled={loadingExport?.exportUsers ? true: loadingExport.exportDrinks}
          >
            <i className="fas fa-file-export"></i>{' '}
            &nbsp;{loadingExport?.exportUsers ? 'Procesando...': 'Exportar usuarios'}
          </button>
          <CSVLink
            id="exportDrinks"
            filename={'Bebidas Alquimia'}
            data={dataExported.drinks.data} 
            headers={dataExported.drinks.headers}
            className="hide"
          ></CSVLink>
          <button
            className="btn btn-accept btn-sm ml-3"
            onClick={()=>handleCsv('exportDrinks')}
            disabled={loadingExport?.exportDrinks ? true: loadingExport.exportUsers}
          >
            <i className="fas fa-file-export"></i>{' '}
            &nbsp;{loadingExport?.exportDrinks ? 'Procesando...': 'Exportar bebidas'}
          </button>
        </>
        <ContentHeader
          title="Bebidas"
          breadcrumb="Bebidas del evento"
          windowTitle="Bebidas del evento"
        />
        <LayoutTable
          exportImportButton={false}
          data={list}
          title="Bebidas"
          gql={DELETE_DRINK}
          requery={GET_DRINKS}
          totalCount={total}
          pagePagination={pagePagination}
          setPagePagination={setPagePagination}
          loadingData={loading}
          FilterBarPlaceholder="Buscar por titulo o descripcion"
          hideBtnDelete={_user.role == 2}
        />
      </>
    )}
    </>
  )
}

export default Drinks
