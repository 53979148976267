import { PrivateRoute } from '../Routers/PrivateRoute'
import React from 'react'

//Users app
import AppUsers from '../Components/Admin/Catalogs/AppUsers/AppUsers'
import AppUsersNew from '../Components/Admin/Catalogs/AppUsers/AppUsersNew'
//App User Roles
import AppUserRoles from '../Components/Admin/Catalogs/AppUserRoles/AppUserRoles'
import AppUserRolesNew from '../Components/Admin/Catalogs/AppUserRoles/AppUserRolesNew'

const submoduleRoutesGenerator = (
  read,
  create,
  edit,
  imports,
  module,
  isAuth
) => {
  const components = {
    //Users app
    AppUsers,
    AppUsersNew,
    //App User Roles 
    AppUserRoles,
    AppUserRolesNew,
  }
  let routesArray = []
  if (create) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/new`}
        component={components[`${module?.submodule_info?.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (read) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}`}
        component={components[module?.submodule_info?.name]}
        isAuth={isAuth}
      />
    )
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/details/:id/:show`}
        component={components[`${module?.submodule_info?.name}New`]}
        isAuth={isAuth}
      />
    )
  }
  if (edit) {
    routesArray.push(
      <PrivateRoute
        exact
        path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/edit/:id`}
        component={components[`${module?.submodule_info?.name}New`]}
        isAuth={isAuth}
      />
    )
    if (module?.submodule_info?.name === 'ProductPosition') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/refill/:id`}
          component={components[`Refill`]}
          isAuth={isAuth}
        />,
        <PrivateRoute
          exact
          path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/new/palletCreation`}
          component={components[`NewPallet`]}
          isAuth={isAuth}
        />
      )
    }
    if (module?.submodule_info?.name === 'InventoryGeneral') {
      routesArray.push(
        <PrivateRoute
          exact
          path={`${module?.module_info?.relative_link}${module?.submodule_info?.relative_link}/refill/:id`}
          component={components[`InventoryGeneralNew`]}
          isAuth={isAuth}
        />
      )
    }
    if (imports) {
      routesArray.push(
        <PrivateRoute
          exact
          key={module.id}
          path={`${module?.module_info?.relative_link}/import/:form`}
          component={components[`${module?.module_info?.name}New`]}
          isAuth={isAuth}
        />
      )
    }
  }
  return routesArray
}
export default submoduleRoutesGenerator
