import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

/**
 * Parameters
 * 
 * reportFilter: active de second mode that show a filter dates and a button to download reports
 * reportFilterHandleButton: Function that will be executed to download report
 * reportFilterHandleButtonText: Text that show on reportFilterHandleButton
 * handleChangeDates: Function that change dates values, it will send two values -> 
 *                    1) Inputs value
 *                    2) Inputs type (The name of a field that will be saved using hook)
 */

export const ContentHeader = ({
  title = 'Title',
  breadcrumb = 'Inicio',
  windowTitle = 'Window Title',

  /**Variables to display a report filter */
  reportFilter = false,
  reportFilterHandleButton = ()=>{},
  reportFilterHandleButtonText = "Descargar reporte",
  handleChangeDates = ()=>{},

  /**Variables to display a date filter */
  dateFilter = false,
  dateFilterHandleChangeDates = ()=>{},
}) => {
  const _title = JSON.parse(localStorage.getItem('configSys'))

  useEffect(() => {
    document.title = `${_title?.project_name} | ${windowTitle}`
  }, [title, windowTitle, _title?.project_name])

  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="header-title">{title}</h1>
          </div>
          {reportFilter ? (
            <>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <div>
                      <label className='title-filter' htmlFor='dateStart'>Desde:&nbsp;</label>
                      <input 
                        id="dateStart" 
                        onChange={e => handleChangeDates(e,"start")} 
                        className='inputDateColorado' 
                        type="date" 
                      />
                      <label className='title-filter' htmlFor='dateEnd'>&nbsp;Hasta:&nbsp;</label>
                      <input
                        id="dateEnd" 
                        onChange={e => handleChangeDates(e,"end")} 
                        className='inputDateColorado mr-3' 
                        type="date" 
                      />
                    </div>
                  </li>
                  <li>
                    <button 
                      className='btn btn-accept btn-sm'
                      onClick={reportFilterHandleButton}
                    >{reportFilterHandleButtonText}</button>
                  </li>
                </ol>
              </div>
            </>
          ):(
            dateFilter ? (
              <>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <div>
                        <label className='title-filter' htmlFor='dateStart'>Desde:&nbsp;</label>
                        <input 
                          id="dateStart" 
                          onChange={e => dateFilterHandleChangeDates(e,"start")} 
                          className='inputDateColorado' 
                          type="date" 
                        />
                        <label className='title-filter' htmlFor='dateEnd'>&nbsp;Hasta:&nbsp;</label>
                        <input
                          id="dateEnd" 
                          onChange={e => dateFilterHandleChangeDates(e,"end")} 
                          className='inputDateColorado mr-3' 
                          type="date" 
                        />
                      </div>
                    </li>
                  </ol>
                </div>
              </>
            ):(
              <>
                {breadcrumb !== '' && (
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Panel</Link>
                      </li>
                      <li className="breadcrumb-item active">{breadcrumb}</li>
                    </ol>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default ContentHeader
