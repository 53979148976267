import React, { useContext } from 'react'
import { AuthContext } from '../../../Auth/AuthContext'
import Instructions from '../../../Assets/Images/instructions/Landing.jpg'

export const Content = () => {
  const { user } = useContext(AuthContext)

  return (
    // <div className="container-fluid dashboard bg-red-color">
    <div className="container dashboard bg-red-color d-flex flex-column">
      {/**--- Twelfth section */}
      <div className="row p-3 text-center">
        <div className='col-12 number-identify'>
          <p>Numero de Usuario #{user.idUser}</p>
        </div>
        <div className="col-12 mb-5 imgResonsive">
          <img src={Instructions} alt="alquimia" className='instructions-img'/>
        </div>
      </div>
    </div>
  )
}

export default Content
