const VoteItem = ({ title = '', src = null, key = 0, displayTitle = true, }) => {
  return (
    <div key={key} className="vote-item">
      {displayTitle && title && <h2 className="vote-item-title">{title}</h2>}
      {src && <img src={src} alt="drink-image" />}
    </div>
  )
}

export default VoteItem
