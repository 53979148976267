import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import LoadingLayer from './LoadingLayer'
import Loader from './Spinner'

export const Box = ({
  title = 'title',
  subTitle = null,
  btnGreenTxt = false,
  btnGreenPath = '/',
  btnRedTxt = false,
  btnRedPath = '/',
  content = '',
  btnSubmit = false,
  btnSubmitNoEnter = false,
  btnState = false,
  btnOnClick = false,
  errors = false,
  btnFunction = false,
  btnFunctionRed = false,
  btnFunctionTitle = 'Regresar',
  btnSecondFunction = false,
  btnSecondFunctionRed = false,
  btnSecondFunctionTitle = 'Regresar',
  btnIcon = 'fa fa-plus',
  withIcons = false,
  withCard = true,
  btnSubmitText,
  withFilters = false,
  params = {},
  setParams = () => { },
  totalPages = 0,
  actualPage = 0,
  handleNextPage = () => { },
  handlePrevPage = () => { },
  setActualPage = () => { },
  setTotalPages = () => { },
  withPagination = false,
  loading,
  ...props
}) => {
  const { id: _id } = useParams()
  useEffect(() => {
    if (Object.values(errors).length > 0) {
      toastSweetAlert({
        mode: 'error',
        message: 'Debes de completar el formulario',
      })
    }
  }, [errors])
  return (
    <>
      <div className={withCard ? 'card' : 'row'}>
        {withCard && (
          <div className="card-header">
            <h3 className="card-title">{title}</h3>
            {subTitle && (
              <h6 className="card-subTitle" style={{ marginTop: '4px' }}>
                &nbsp;{subTitle}
              </h6>
            )}
          </div>
        )}
        {withFilters && (
          <div className="col-12 mt-5">
            <div className="col-6">
              <input
                type="text"
                placeholder="Search by name"
                className="form-control position-searchInput"
                onChange={(e) => {
                  setParams({
                    offset: null,
                    limit: 5,
                    searchQuery: e.target.value,
                  })
                  setActualPage(1)
                  setTotalPages(1)
                }}
                value={params.searchQuery}
              />
            </div>
          </div>
        )}

        <div className={withCard ? 'card-body' : 'col col-md-12'} {...props}>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          ) : (
            content
          )}

          <div className="control-btn-box">
            {btnGreenTxt && (
              <Link
                to={btnGreenPath}
                className="btn StandarModalConfirmButtonLogOut"
              >
                {withIcons && <i className="fa fa-plus"></i>} {btnGreenTxt}
              </Link>
            )}
            {btnSubmit && (
              <button
                type="submit"
                onClick={typeof btnSubmit === 'function' ? btnSubmit : () => { }}
                className="btn StandarModalConfirmButtonLogOut"
                disabled={btnState ? true : false}
              >
                {withIcons && (
                  <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
                )}
                {btnState && !btnSubmitText
                  ? ' Procesando...'
                  : _id && !btnSubmitText
                    ? ' Actualizar'
                    : !btnSubmitText && ' Guardar'}
                {btnState && btnSubmitText ? ' Procesando...' : btnSubmitText}
              </button>
            )}
            {btnSubmitNoEnter && (
              <span
                type="submit"
                onClick={
                  typeof btnSubmitNoEnter === 'function'
                    ? btnSubmitNoEnter
                    : () => { }
                }
                className="btn StandarModalConfirmButtonLogOut"
                disabled={btnState ? true : false}
              >
                {withIcons && (
                  <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
                )}
                {btnState ? ' Procesando...' : _id ? ' Actualizar' : ' Guardar'}
              </span>
            )}
            {btnRedTxt && (
              <Link
                to={btnRedPath}
                className="btn StandarModalCancelButtonLogOut"
              >
                {withIcons && <i className="fa fa-times"></i>} {btnRedTxt}
              </Link>
            )}
            {btnOnClick && (
              <button
                type="button"
                onClick={
                  typeof btnOnClick === 'function' ? btnOnClick : () => { }
                }
                className="btn StandarModalConfirmButtonLogOut"
                disabled={btnState ? true : false}
              >
                {withIcons && (
                  <i className={`${_id ? 'fa fa-edit' : 'fa fa-save'}`}></i>
                )}
                {btnState ? ' Procesando...' : _id ? ' Actualizar' : ' Guardar'}
              </button>
            )}
            {btnFunction && (
              <span
                onClick={
                  typeof btnFunction === 'function' ? btnFunction : () => { }
                }
                className={
                  btnFunctionRed
                    ? 'btn btn-danger StandarModalCancelButtonLogOut'
                    : 'btn StandarModalConfirmButtonLogOut'
                }
              >
                {btnFunctionTitle}
              </span>
            )}
            {btnSecondFunction && (
              <span
                onClick={
                  typeof btnSecondFunction === 'function'
                    ? btnSecondFunction
                    : () => { }
                }
                className={
                  btnSecondFunctionRed
                    ? 'btn btn-danger StandarModalCancelButtonLogOut'
                    : 'btn StandarModalCancelButtonLogOut'
                }
              >
                {btnSecondFunctionTitle}
              </span>
            )}
          </div>
        </div>
        {withPagination && (
          <div className="d-flex justify-content-center">
            {totalPages > 0 ? (
              <div className="">
                <button
                  id="pagination-previous-page"
                  type="button"
                  aria-label="Previous Page"
                  aria-disabled="true"
                  disabled={actualPage <= 1}
                  className="button_arrow"
                  onClick={handlePrevPage}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  id="pagination-next-page"
                  type="button"
                  aria-label="Next Page"
                  aria-disabled="true"
                  disabled={actualPage >= totalPages}
                  className="button_arrow"
                  onClick={handleNextPage}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <p>
                  Page {actualPage} of {totalPages}
                </p>
              </div>
            ) : (
              <div>No records found</div>
            )}
          </div>
        )}
      </div>
    </>
  )
}
export default Box
