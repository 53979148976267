import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { types } from '../../Types/types'
import { useMutation } from '@apollo/client'
import { AUTH_USER, DECRYPT_TOKEN } from '../../graphql/Auth/auth'
import { GET_ALL_USER_PERMISSIONS } from '../../graphql/Catalog/Users/user'
import { AuthContext } from '../../Auth/AuthContext'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Logo from './../../Assets/Images/vinos-america-logo.png'
import Swal from 'sweetalert2'

export const Login = () => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  const { dispatch } = useContext(AuthContext)
  const [form, setForm] = useState({
    email: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)
  const [pwView, setPwView] = useState(false)

  const [authUser] = useMutation(AUTH_USER)
  const [decryptToken] = useMutation(DECRYPT_TOKEN)
  const [getAllUserPermissions] = useMutation(GET_ALL_USER_PERMISSIONS, {
    fetchPolicy: 'no-cache',
  })

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const { data: dataToken } = await authUser({
        variables: {
          input: {
            email: form.email.toLowerCase().trim(),
            password: String(form.password).trim(),
          },
        },
      })
      const token = dataToken.authUser.token
      localStorage.setItem('token', token)
      const { data: tokenData } = await decryptToken({
        variables: {
          token,
        },
      })
      const decoded = tokenData.decryptToken
      const { data: userPermissionsData } = await getAllUserPermissions({
        variables: {
          userID: decoded.id,
        },
      })
      const userPermissions = userPermissionsData.getAllUserPermissions
      dispatch({
        type: types.login,
        payload: {
          logged: true,
          idUser: decoded.id,
          role: decoded.role,
          email: decoded.email,
          userPermissions,
          avatar: decoded.avatar,
          name: decoded.name,
          phone: decoded.phone,
          idAge: decoded.id_age,
        },
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return Toast.fire({
        icon: 'error',
        title: error.message,
      })
    }
  }

  const handleSeePassword = () => setPwView(!pwView)

  return (
    <div className="full-container">
      <Link to="/" className="h1 d-flex justify-content-center mb-5 mt-5">
        <img src={Logo} className="img-logo-full" alt="logo" />
      </Link>
      <span>Inicio de sesión</span>
      <div className="full-box" id="boxLogin">
        <form
          onSubmit={handleLogin}
          method="post"
          className="row"
          autoComplete="new-password"
        >
          <div className="input-icons-login col-12 iconInside">
            <i className="far fa-user"></i>
            <input
              id="user"
              type="email"
              className="form-control"
              placeholder="Correo electrónico"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              // autoComplete="new-password"
            />
          </div>
          <div className="input-icons-login col-12 iconInside">
            <i className="fas fa-lock"></i>
            <input
              id="pass"
              type="text"
              className={`form-control ${
                pwView
                  ? 'implement-password-view-none'
                  : 'implement-password-view'
              }`}
              placeholder="Password"
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              minlength="4"
              maxlength="4"
              inputmode="numeric"
              pattern="\d*"
            />
            <i className="far fa-eye" onClick={handleSeePassword}></i>
          </div>
          <div className="actions-login col-12 text-center">
            {/* <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="remember"
              />
              <label className="custom-control-label" htmlFor="remember">
                Remember me
              </label>
            </div> */}
            <div>
              <Link to="/recuperar-contraseña">Olvidé mi contraseña</Link>
              <br />
              <Link to="/loginRegister" className="register-link">
                ¿No tienes cuenta? Registrate
              </Link>
            </div>
          </div>
          <div className="login-btn col-12">
            {loading ? (
              <button className="btn btn-login" type="button" disabled>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Procesando...</span>
              </button>
            ) : (
              <button type="submit" className="btn btn-login">
                Iniciar sesión
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
