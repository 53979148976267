import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Box from '../../../Global/Box'
import ContentHeader from '../../../Layout/ContentHeader'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_ROLE,
  GET_ROLES,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
} from '../../../../graphql/Catalog/Roles/roles'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import { useHistory, useParams } from 'react-router-dom'
import InputController from '../../../Global/InputController'
import { AuthContext } from '../../../../Auth/AuthContext'

export const RolesNew = () => {
  const { id: _id } = useParams()
  const { user } = useContext(AuthContext)

  const [createRole] = useMutation(CREATE_ROLE, {
    refetchQueries: [
      {
        query: GET_ROLES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [updateRole] = useMutation(UPDATE_ROLE, {
    refetchQueries: [
      {
        query: GET_ROLES,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      { query: GET_ROLE_BY_ID, variables: { getOneRoleId: parseInt(_id) } },
    ],
  })

  const {
    data: dataRoleId,
    loading: loadingRoleId,
    error: errorRoleId,
  } = useQuery(GET_ROLE_BY_ID, {
    variables: {
      getOneRoleId: parseInt(_id),
    },
  })

  const [loadingBtn, setLoadingBtn] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required('This field is required'),
    description: Yup.string(),
  })

  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loadingRoleId) {
      if (_id) {
        if (errorRoleId) {
          setLoadingBtn(false)
          return toastSweetAlert({
            mode: 'error',
            message: errorRoleId.message,
          })
        }

        reset(dataRoleId?.getOneRole)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, dataRoleId, errorRoleId])

  const onSubmit = async (Data) => {
    setLoadingBtn(true)
    try {
      if (_id) {
        await updateRole({
          variables: {
            roleId: parseInt(_id),
            input: {
              role_name: Data.role_name,
              description: Data.description,
              id_user_update: user.idUser,
            },
          },
        })
        return (
          toastSweetAlert({
            mode: 'ok',
            message: 'Rol has been updated successfully',
          }),
          history.push(`/catalog/roles`)
        )
      } else {
        await createRole({
          variables: {
            input: {
              role_name: Data.role_name,
              description: Data.description,
              id_user_register: user.idUser,
            },
          },
        })
        return toastSweetAlert(
          {
            mode: 'ok',
            message: 'Rol has been created successfully',
          },
          history.push(`/catalog/roles`)
        )
      }
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }
  return (
    <>
      <ContentHeader
        title="User roles"
        breadcrumb="Roles"
        windowTitle={`${_id ? 'Edit' : 'Add'} role `}
      />
      <FormProvider {...methods}>
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
          <Box
            title={`${_id ? 'Edit' : 'Add'} new rol`}
            btnRedPath="/catalog/roles"
            btnRedTxt="Cancel"
            errors={errors}
            btnSubmit={true}
            btnLoading={loadingBtn}
            content={
              <>
                <div className="row">
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Rol name"
                      name="role_name"
                      type="text"
                      placeholder="Rol name"
                      control={control}
                    />
                  </div>
                  <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                    <InputController
                      label="Description"
                      name="description"
                      inputType="textarea"
                      rows={3}
                      placeholder="Description"
                      control={control}
                    />
                  </div>
                </div>
              </>
            }
          />
        </form>
      </FormProvider>
    </>
  )
}
export default RolesNew
