import React, { useEffect, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import Loader from './Spinner'
import debounce from 'lodash.debounce'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { AuthContext } from './../../Auth/AuthContext'
import { useLocation } from 'react-router'
import { EMPTY } from './../../graphql/Auth/auth'
import { useMutation } from '@apollo/client'
import { CSVLink } from 'react-csv'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom'

export const LayoutTable = ({
  data = [],
  title,
  gql = EMPTY,
  requery = EMPTY,
  refetchFunctionQueries = async () => {},
  exportQuery = EMPTY,
  importQuery = EMPTY,
  maximumFilesImport = 1,
  specialImportRoute = false,
  importMessageValidation = 'Archivos necesarios',
  editText = <i className="fas fa-edit"></i>,
  hideDetails = false,
  hideActions = false,
  actionBtnEdit = false,
  sizeProductName = '300px',
  hideBtnEdit = false,
  actionBtnErase = false,
  hideBtnDelete = false,
  changeCustomActionErase = false,
  platformSelector = false,
  paginationServer = true,
  totalCount,
  pagePagination,
  setPagePagination,
  mutationAction = false,
  hideFilterBar = false,
  FilterBarPlaceholder = false,
  loadingData,
  handleButton,
  actionsAddProducts = false,
  handleButtonRowCondition = false,
  handleButtonClassName = '',
  handleButtonTitle = 'Action',
  handleButtonType = 'button',
  headersButtons = true,
  exportImportButton = true,
  alertAction = false,
  alertActionText = '',
  addBtnTxt,
  filter = false,
  filterOptions = [],
  filterOptionsSecond = [],
  filterOnChange = () => {},
  filterOnChangeSecond = () => {},
  filterDefaultOptionText = false,
  filterDefaultOptionTextSecond = false,
  defaultValueSelectSecond = false,
  filterLabelText = false,
  filterLabelTextSecond = false,
  filterSecond = false,
  extraHeaderText,
  extraHeaderButtonTo = '',
  extraHeaderButton,
  hideId = false,
  titleActions = 'Actions',
  withCard = true,
  extraArgHandleButton = null,
  handleButtonModal = () => {},
  handleButtonModalText = false,
  expandableRows = false,
  expandableRowsComponent = <div></div>,
  alertColor = '#ffd281',
  listFormat = false,
  hidePagination = false,
  defaultValueSelect = false,

  handleButtonSecond = false,
  handleButtonSecondTitle = '',
  noDataComponent = 'Sin información',
  extraHeaderButtonFunction = false,
  extraHeaderButtonFunctionText = 'Función',
  filterLabelDate = 'Fecha salida',
  filterOnChangeDate = false,
  hideAddNew = false,
}) => {
  const history = useHistory()
  const [columns, setColumns] = useState([])
  const [cell, setCell] = useState([])
  const [dataExported, setDataExported] = useState([])
  const [FileNameExported, setFileNameExported] = useState('')
  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingImport, setLoadingImport] = useState(false)
  const location = useLocation()
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    erase: false,
    exportBtn: false,
    importBtn: false,
    permissionsBtn: false,
    cycleCountcycleOperationBtn: false,
    path: '',
  })
  const MySwal = withReactContent(Swal)

  const {
    create,
    edit,
    erase,
    exportBtn,
    importBtn,
    permissionsBtn,
    cycleCountcycleOperationBtn,
    path,
  } = permissions
  const { user } = useContext(AuthContext)

  const [deleteItem] = useMutation(gql)

  const [exportItems] = useMutation(exportQuery)
  const [importItems] = useMutation(importQuery)

  //SET PERMISSIONS
  useEffect(() => {
    const permissions = user?.userPermissions
    let listPermissions = []
    permissions.map((item) => {
      let moduleName = `${item?.module_info?.relative_link}`
      if (moduleName === location.pathname) {
        return (listPermissions = {
          create: item.access_retrieve,
          edit: item.access_edit,
          erase: item.access_delete,
          exportBtn: item.access_export,
          importBtn: item.access_import,
          path: item.module_info.relative_link,
        })
      }
      return null
    })
    permissions.map((item) => {
      let submoduleName = item.submodule_info?.relative_link
      if (submoduleName) {
        if (location.pathname.includes('new')) {
          const relativePath = location.pathname
            .split('new')[0]
            .replace(/.$/, '')
          const relativePathSplit = relativePath.split('/')
          const submodulePath = relativePathSplit[relativePathSplit.length - 1]
          if (submoduleName === `/${submodulePath}`) {
            return (listPermissions = {
              create: item.access_retrieve,
              edit: item.access_edit,
              erase: item.access_delete,
              exportBtn: item.access_export,
              importBtn: item.access_import,
              path: `${item.module_info.relative_link}/${submodulePath}`,
            })
          }
        }
        if (location.pathname.includes('edit')) {
          const relativePath = location.pathname
            .split('edit')[0]
            .replace(/.$/, '')
          const relativePathSplit = relativePath.split('/')
          const submodulePath = relativePathSplit[relativePathSplit.length - 1]
          if (submoduleName === `/${submodulePath}`) {
            return (listPermissions = {
              create: item.access_retrieve,
              edit: item.access_edit,
              erase: item.access_delete,
              exportBtn: item.access_export,
              importBtn: item.access_import,
              path: `${item.module_info.relative_link}/${submodulePath}`,
            })
          }
        }
        const relativePathSplit = location.pathname.split('/')
        const submodulePath = relativePathSplit[relativePathSplit.length - 1]
        if (submoduleName === `/${submodulePath}`) {
          return (listPermissions = {
            create: item.access_retrieve,
            edit: item.access_edit,
            erase: item.access_delete,
            exportBtn: item.access_export,
            importBtn: item.access_import,
            path: `${item.module_info.relative_link}/${submodulePath}`,
          })
        }
      }
      return true
    })
    if (
      location.pathname === '/catalog/users' ||
      location.pathname === '/ticket/types'
    ) {
      const checkBtnPermissions = permissions.some((permission) => {
        if (
          permission?.module_info.name === 'UsersPermissions' ||
          permission?.module_info.name === 'TicketTypesPermissions'
        ) {
          if (permission?.access_read) {
            return true
          } else {
            return false
          }
        }
        return null
      })
      listPermissions.permissionsBtn = checkBtnPermissions
    }

    if (location.pathname === '/inventories/cycleCountRequest') {
      const checkBtnCycleCountcycleOperation = permissions.some(
        (permission) => {
          if (permission?.submodule_info?.name === 'CycleCountcycleOperation') {
            return permission?.access_read ? true : false
          }
          return null
        }
      )
      listPermissions.cycleCountcycleOperationBtn =
        checkBtnCycleCountcycleOperation
    }

    return setPermissions(listPermissions)
  }, [location.pathname, user])

  //SETTING DATA
  useEffect(() => {
    let columns = []
    setCell(data)
    if (data.length === 0) return
    Object.keys(data[0]).map((item, index) => {
      if (item === 'id') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          sortable: true,
          width: '80px',
          omit: hideId,
        })
      }
      if (item === 'alert') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          omit: true,
        })
      }
      if (item === 'orderIdRoutesForm') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          omit: true,
        })
      }

      if (item === 'órdenes de compra') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          sortable: true,
        })
      }
      if (item === 'item_code') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          omit: true,
        })
      }
      if (item === 'Progreso') {
        return columns.push({
          name: item,
          selector: (row) => (
            <div
              className="progress"
              style={{ backgroundColor: '#b0c0d0', width: '100%' }}
            >
              <div
                className={`progress-bar progress-bar-striped progress-bar-animated
                  ${
                    row[item] < 25
                      ? 'bg-danger'
                      : row[item] >= 25 && row[item] < 50
                      ? 'bg-warning'
                      : row[item] >= 50 && row[item] < 75
                      ? 'bg-primary'
                      : row[item] >= 75 && 'bg-success'
                  }
                 
                `}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${row[item]}%` }}
              >
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
              </div>
            </div>
          ),
          sortable: false,
          width: '180px',
        })
      }
      if (item === 'expandable') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          omit: true,
        })
      }
      if (item === 'orderShippingId') {
        return columns.push({
          name: item,
          selector: (row) => row[item],
          omit: true,
        })
      }
      //Para mostrar la imagen en el catalogo de productos para agregar en nuevo pedido
      if (item === 'img_url') {
        return columns.push({
          name: 'Imagen',
          cell: (row) => (
            <>
              <div className="text-center w-100 my-1">
                <img
                  src={row[item]}
                  style={{ maxWidth: '100%', maxHeight: '100px' }}
                  alt="ordersImages"
                />
              </div>
            </>
          ),
        })
      }
      //Para poder seleccionar la cantidad del producto a agregar en nuevo pedido
      if (item === 'quantity_select') {
        return columns.push({
          name: 'Cantidad',
          width: '150px',
          cell: (row) => {
            // let limite = row[item] > 5 ? 5 : row[item];
            // let array = []
            // for(let i = 0; i < limite; i++){
            //   array.push(i)
            // }

            return (
              <>
                <div id="principal" className="text-center w-100 my-1">
                  {/* <select id={`select-quantity-${row["sku"]}`} name="select-quantity" className="form-control" onChange={(e)=>{row["quantity_selectioned"] = parseInt(e.target.value)}}>
                  {array.map((item, index)=><option key={index}>{item + 1}</option>)}
                </select> */}
                  <input
                    type="number"
                    className="form-control"
                    placeholder={row[item] + ' máximo'}
                    onChange={(e) => {
                      row['quantity_selectioned'] = parseInt(e.target.value)
                    }}
                  />
                </div>
              </>
            )
          },
        })
      }
      //Para que el nombre del producto se visualice correctamente en nuevo pedido
      if (item === 'product_add') {
        return columns.push({
          name: 'nombre',
          width: sizeProductName,
          selector: (row) => row[item],
          sortable: true,
        })
      }
      return columns.push({
        name: item,
        selector: (row) => row[item],
        sortable: true,
      })
    })

    if (!hideActions) {
      columns.push({
        name: titleActions,
        button: true,
        width: !cycleCountcycleOperationBtn ? '200px' : '230px',
        cell: (row) => (
          <>
            <div
              className={
                handleButton && handleButtonRowCondition
                  ? 'btn-group btn-actions-table noGap'
                  : 'btn-group btn-actions-table'
              }
            >
              {handleButtonSecond && (
                <span
                  className="btn btn-accept btn-sm"
                  onClick={() => handleButtonSecond(row['id'])}
                >
                  {handleButtonSecondTitle}
                </span>
              )}

              {handleButton ? (
                <>
                  {handleButtonType === 'checkbox' ? (
                    <>
                      <input
                        id={row['id'] + '-checkBoxLay'}
                        type="checkbox"
                        onClick={() => handleButton(row['id'])}
                      ></input>
                    </>
                  ) : (
                    <>
                      {/* Span */}
                      <span
                        className={`btn btn-accept btn-sm ${handleButtonClassName}`}
                        onClick={() =>
                          handleButton(row['id'], extraArgHandleButton)
                        }
                      >
                        {handleButtonTitle}
                      </span>
                    </>
                  )}
                </>
              ) : (
                !hideDetails &&
                !handleButton &&
                !handleButtonRowCondition && (
                  <Link
                    className="btn btn-accept btn-sm"
                    to={`${path}/details/${row['id']}/show`}
                  >
                    Detalles
                  </Link>
                )
              )}

              {!hideBtnEdit ? (
                edit &&
                !handleButton &&
                !handleButtonRowCondition &&
                !actionBtnEdit ? (
                  <Link
                    className="btn btn-accept btn-sm"
                    to={`${path}/edit/${row['id']}`}
                  >
                    {editText}
                  </Link>
                ) : (
                  actionBtnEdit &&
                  edit && (
                    <span
                      className="btn btn-accept btn-sm"
                      onClick={() => actionBtnEdit(row['id'])}
                    >
                      {editText}
                    </span>
                  )
                )
              ) : (
                <></>
              )}

              {cycleCountcycleOperationBtn && handleButtonModalText && (
                <button
                  className="btn btn-accept btn-sm"
                  onClick={() => handleButtonModal(row['id'])}
                >
                  <i className="fas fa-play"></i>
                </button>
              )}

              {!actionBtnErase &&
              !handleButton &&
              !handleButtonRowCondition &&
              !hideBtnDelete &&
              !changeCustomActionErase ? (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleDelete(row['id'])}
                >
                  <i className="fas fas fa-trash"></i>
                </button>
              ) : (
                actionBtnErase &&
                !hideBtnDelete &&
                erase && (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => actionBtnErase(row['id'])}
                  >
                    <i className="fas fas fa-trash"></i>
                  </button>
                )
              )}
              {permissionsBtn && !handleButton && !handleButtonRowCondition && (
                <Link
                  className="btn btn-warning btn-sm"
                  to={`${path}/permissions/edit/${row['id']}`}
                >
                  <i className="fas fa-lock"></i>
                </Link>
              )}
              {row.alert &&
                alertAction &&
                edit &&
                !handleButton &&
                !handleButtonRowCondition && (
                  <Link
                    className="btn btn-accept btn-sm"
                    to={`${path}/refill/${row['id']}`}
                  >
                    {alertActionText}
                  </Link>
                )}
              {row.alert &&
                alertAction &&
                edit &&
                handleButton &&
                handleButtonRowCondition && (
                  <Link
                    className="btn btn-accept btn-sm buttonWithoutSpace"
                    to={`${path}/refill/${row['id']}`}
                  >
                    {alertActionText}
                  </Link>
                )}
            </div>
          </>
        ),
      })
    } else if (actionsAddProducts) {
      columns.push({
        name: titleActions,
        button: true,
        width: '100px',
        cell: (row) => (
          <>
            <div
              className={
                handleButton && handleButtonRowCondition
                  ? 'btn-group btn-actions-table noGap'
                  : 'btn-group btn-actions-table'
              }
            >
              {handleButton && (
                <>
                  <span
                    className={`btn btn-accept btn-md ${handleButtonClassName}`}
                    onClick={() => handleButton(row, extraArgHandleButton)}
                  >
                    {handleButtonTitle}
                  </span>
                </>
              )}
            </div>
          </>
        ),
      })
    }
    setColumns(columns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    edit,
    erase,
    permissionsBtn,
    cycleCountcycleOperationBtn,
    path,
    loadingExport,
    loadingImport,
    dataExported,
    loadingData,
  ])

  //PAGINATION OPTIONS DATA TABLE
  const Options = {
    rowsPerPageText: 'Rows per page',
    rangeSeparatorText: 'of',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.alert,
      style: {
        backgroundColor: alertColor,
      },
    },
  ]

  const handleDelete = async (id) => {
    MySwal.fire({
      showCloseButton: true,
      icon: 'warning',
      title: '¿Estas seguro de eliminar el registro?',
      html: (
        <>
          <div className="message-container">Esta accion es permanente</div>
        </>
      ),
      allowOutsideClick: false,
      buttonsStyling: false,
      showDenyButton: true,
      confirmButtonText: 'Aceptar',
      denyButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        title: 'StandarModalTitle',
        htmlContainer: 'StandarModalHtmlContainer',
        confirmButton: 'StandarModalConfirmButtonLogOut',
        denyButton: 'StandarModalCancelButtonLogOut',
        footer: 'StandarModalFooterLogOut',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (requery) {
            await deleteItem({
              variables: {
                id: parseInt(id),
                userId: parseInt(user.idUser),
              },
              refetchQueries: [
                {
                  query: requery,
                  variables: { ...pagePagination },
                },
              ],
            })
          } else {
            await refetchFunctionQueries()
          }
          return toastSweetAlert({
            mode: 'ok',
            message: 'Registro ha sido eliminado',
          })
        } catch (error) {
          toastSweetAlert({
            mode: 'error',
            message: error.message,
          })
        }
      }
    })
  }

  //LOGIC FOR FILTER DATA
  const handleSearch = (value) => {
    const query = value.target.value.toLowerCase()
    if (!query) {
      setCell(data)
    }
    setPagePagination({
      ...pagePagination,
      searchQuery: query,
    })
  }

  //LOGIC DATA PAGINATION
  const handlePageChange = (page) => {
    if (!paginationServer) return
    if (mutationAction) {
      localStorage.setItem('limit', 10)
      localStorage.setItem(
        'offset',
        parseInt(localStorage.getItem('limit')) * (page - 1)
      )

      // setPagePaginationMutation({
      //   limit: 10,
      //   offset: 0,
      //   searchQuery: pagePaginationMutation?.searchQuery,
      //   platform: pagePaginationMutation?.platform,
      // })
      mutationAction()
      return
    }
    setPagePagination({
      ...pagePagination,
      offset: pagePagination.limit * (page - 1),
    })
  }

  const handlePerRowsChange = async (newPerPage) => {
    if (!paginationServer) return
    if (mutationAction) {
      localStorage.setItem('limit', newPerPage)

      // setPagePaginationMutation({
      //   ...pagePaginationMutation,
      //   limit: newPerPage,
      // })
      mutationAction()
      return
    }
    setPagePagination({ ...pagePagination, limit: newPerPage })
  }

  const getDataToExport = async () => {
    try {
      const dataExported = await exportItems()
      const nameOperation = exportQuery.definitions[0].name.value

      const camelCaseNameOperation =
        nameOperation.charAt(0).toLowerCase() + nameOperation.slice(1)

      const fileName = nameOperation.slice(6)
      setFileNameExported(fileName)
      setDataExported(dataExported.data[`${camelCaseNameOperation}`])

      document.getElementById('exportfileCSV').click()

      setLoadingExport(false)
      return toastSweetAlert({
        mode: 'ok',
        message: 'CSV Generado',
      })
    } catch (error) {
      setLoadingExport(false)
      toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const importCSVData = async () => {
    try {
      document.getElementById('importfileCSV').click()
    } catch (error) {
      toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const handleFileCSV = async (e) => {
    try {
      if (!e.target.files[0]) {
        return
      }
      for (const file of e.target.files) {
        if (file.type !== 'text/csv')
          return toastSweetAlert({
            mode: 'error',
            message: 'Solo se admiten archivos CSV',
          })
      }
      if (e.target.files.length < maximumFilesImport) {
        return toastSweetAlert({
          mode: 'error',
          message: `Esta importación requiere mínimo de ${maximumFilesImport} ${
            maximumFilesImport > 1 ? 'documentos.' : 'documento.'
          }
          Archivos necesarios: ${importMessageValidation}
          `,
        })
      }

      if (e.target.files.length > maximumFilesImport) {
        return toastSweetAlert({
          mode: 'error',
          message: `Esta importación admite un máximo de ${maximumFilesImport} ${
            maximumFilesImport > 1 ? 'documentos.' : 'documento.'
          }
          Archivos necesarios: ${importMessageValidation}
          `,
        })
      }

      // const validate = await fileValidator(e.target.files[0], 'all')

      // if (!validate) {
      //   return toastSweetAlert({
      //     mode: 'error',
      //     message: 'Solo se admite CSV',
      //   })
      // }
      setLoadingImport(true)
      toastSweetAlert({
        mode: 'loading',
        message: 'Cargando información, espere...',
      })
      await importItems({
        variables: {
          files: e.target.files,
        },
      })
      setLoadingImport(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          message: 'La importación se hizo correctamente',
        },
        history.push(window.location.pathname)
      )
    } catch (error) {
      setLoadingImport(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  const disableDates = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    return `${yyyy}-${mm}-${dd}`
  }

  return (
    <div
      className={`${withCard && 'card'} layout-configs ${
        loadingImport ? 'processing-file' : ''
      }`}
    >
      <div className={`${withCard && 'card-header'}`}>
        <h3 className="card-title">{title}</h3>
        <div className="control-btn-box">
          <>
            <div
              className="btn-group actions-btn"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <div className="btn-group" role="group">
                {exportImportButton &&
                  headersButtons &&
                  (importBtn || exportBtn) && (
                    <>
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-accept btn-sm"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        disabled={loadingExport}
                      >
                        <i className="fas fa-cloud-download-alt"></i>
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <>
                          {exportBtn && (
                            <>
                              <CSVLink
                                id="exportfileCSV"
                                filename={FileNameExported}
                                data={dataExported}
                                className="hide"
                              ></CSVLink>
                              <span
                                className="dropdown-item pointer"
                                onClick={getDataToExport}
                              >
                                <i className="fas fa-file-export"></i>{' '}
                                &nbsp;Exportar CSV
                              </span>
                            </>
                          )}
                          {importBtn && specialImportRoute ? (
                            <>
                              <span
                                className="dropdown-item pointer"
                                onClick={history.push(specialImportRoute)}
                              >
                                <i className="fas fa-file-import"></i>{' '}
                                &nbsp;Importar CSV
                              </span>
                            </>
                          ) : (
                            importBtn && (
                              <>
                                <input
                                  type="file"
                                  id="importfileCSV"
                                  className="hide"
                                  onChange={(e) => handleFileCSV(e)}
                                  multiple={
                                    maximumFilesImport > 1 ? true : false
                                  }
                                  accept={'.csv'}
                                />
                                <span
                                  className="dropdown-item pointer"
                                  onClick={importCSVData}
                                >
                                  <i className="fas fa-file-import"></i>{' '}
                                  &nbsp;Importar CSV
                                </span>
                              </>
                            )
                          )}
                        </>
                      </div>
                    </>
                  )}
              </div>

              {create && headersButtons && !hideAddNew && (
                <Link to={`${path}/new`} className="btn btn-accept btn-sm">
                  <i className="fa fa-plus"></i>{' '}
                  {addBtnTxt ? addBtnTxt : 'Agregar nuevo'}
                </Link>
              )}
              {create && headersButtons && extraHeaderButton && (
                <Link
                  to={extraHeaderButtonTo}
                  className="btn btn-accept btn-sm"
                >
                  {extraHeaderText}
                </Link>
              )}
              {create && headersButtons && extraHeaderButtonFunction && (
                <span
                  onClick={extraHeaderButtonFunction}
                  className="btn btn-accept btn-sm"
                >
                  {extraHeaderButtonFunctionText}
                </span>
              )}
            </div>
          </>
        </div>
      </div>
      <div className="card-body">
        <div
          className={`${
            listFormat
              ? 'hidePagination'
              : hidePagination && cell.length < 10
              ? 'hidePagination'
              : ''
          }`}
        >
          <DataTable
            columns={columns}
            data={cell}
            expandOnRowClicked
            expandableRows={expandableRows}
            expandableRowsComponent={expandableRowsComponent}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            highlightOnHover
            pagination
            paginationComponentOptions={Options}
            pointerOnHover
            // responsive
            striped
            subHeader
            subHeaderAlign="left"
            subHeaderWrap
            conditional
            // actions={
            //   platformSelector && (
            //     <div className="platform-selector">
            //       <div className="row">
            //         <div className="col-s6 mr-3">
            //           <i className="fa fa-filter"></i>
            //         </div>
            //         <div className="col-s6">
            //           <select
            //             className="form-control"
            //             type="select"
            //             onChange={(e) =>
            //               setPagePagination({
            //                 ...pagePagination,
            //                 platform: parseInt(e.target.value),
            //               })
            //             }
            //           >
            //             <option value="" disabled>
            //               Selecciona una opción
            //             </option>
            //             <option value="">Todas</option>
            //             <option value="1">Woocomerce</option>
            //             <option value="2">MercadoLibre</option>
            //             <option value="3">Amazon</option>
            //           </select>
            //         </div>
            //       </div>
            //     </div>
            //   )
            // }
            subHeaderComponent={
              <div
                id="headerFilters"
                className="row"
                style={{
                  justifyContent:
                    hideFilterBar && filter ? 'end' : 'space-between',
                }}
              >
                {!hideFilterBar && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    {filter && filterLabelText && (
                      <label style={{ visibility: 'hidden' }}>hide</label>
                    )}
                    <input
                      id="searchBar"
                      type="text"
                      className="input-field form-control position-searchInput"
                      placeholder={
                        FilterBarPlaceholder
                          ? FilterBarPlaceholder
                          : 'Buscar...'
                      }
                      onChange={debounce(handleSearch, 300)}
                    />
                  </div>
                )}
                {filter && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    {filterLabelText && (
                      <label htmlFor="filter">{filterLabelText}</label>
                    )}
                    <select
                      id="filter"
                      name="filter"
                      type="select"
                      className="input-field form-control position-searchInput"
                      placeholder="Select an option"
                      onChange={filterOnChange}
                      defaultValue={
                        defaultValueSelect ? defaultValueSelect : ''
                      }
                    >
                      <option
                        value=""
                        readOnly={defaultValueSelect}
                        disabled={defaultValueSelect}
                      >
                        {filterDefaultOptionText
                          ? filterDefaultOptionText
                          : 'Todos'}
                      </option>
                      {filterOptions.map((option) => option)}
                    </select>
                  </div>
                )}
                {filterSecond && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    {filterLabelTextSecond && (
                      <label htmlFor="filterSecond">
                        {filterLabelTextSecond}
                      </label>
                    )}
                    <select
                      id="filterSecond"
                      name="filterSecond"
                      type="select"
                      className="input-field form-control position-searchInput"
                      placeholder="Select an option"
                      onChange={filterOnChangeSecond}
                      defaultValue={
                        defaultValueSelectSecond ? defaultValueSelectSecond : ''
                      }
                    >
                      <option
                        value=""
                        readOnly={defaultValueSelectSecond}
                        disabled={defaultValueSelectSecond}
                      >
                        {filterDefaultOptionTextSecond
                          ? filterDefaultOptionTextSecond
                          : 'Todos'}
                      </option>
                      {filterOptionsSecond.map((option) => option)}
                    </select>
                  </div>
                )}
                {filterOnChangeDate && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    {filterLabelTextSecond && (
                      <label htmlFor="filterDate">{filterLabelDate}</label>
                    )}
                    <input
                      id="filterDate"
                      name="filterDate"
                      type="date"
                      className="input-field form-control position-searchInput"
                      onChange={filterOnChangeDate}
                      defaultValue={new Date()}
                      min={disableDates()}
                    />
                  </div>
                )}
              </div>
            }
            conditionalRowStyles={conditionalRowStyles}
            paginationServer={paginationServer}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalCount}
            noDataComponent={noDataComponent}
            progressPending={loadingData}
            progressComponent={<Loader />}
          />
        </div>
      </div>
    </div>
  )
}
export default LayoutTable
