import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import LogoAlquimia from '../../../../src/Assets/Images/votes/logo-2.png'

const VoteTable = ({
  data,
  headers,
  total,
  pagePagination,
  setPagePagination,
}) => {
  //-------- Hooks to save data --------
  const [loading, setLoading] = useState(true)
  //-------- UseEffects ---------
  useEffect(() => {
    setLoading(false)
  }, [])

  //-------- Functions ---------
  const handlePageClick = (event) => {
    if (setPagePagination && pagePagination) {
      setPagePagination({
        ...pagePagination,
        offset: event.selected * pagePagination.limit,
      })
    }
  }

  return (
    <>
      {!loading && (
        <div className="alquimia-table-box bg-whitegray">
          <div className="alquimia-table-header">
            <img src={LogoAlquimia} alt="alquimia-presentation" />
          </div>
          <div className="table-responsive mb-2">
            <table className="alquimia-table">
              <thead>
                <tr>
                  {headers.map((item, index) => (
                    <th
                      className={index + '-table-alquimia-header text-center  '}
                      key={index + '-table-alquimia-header'}
                    >
                      <div className='table-header-properties'>
                        <h2 className='table-header-title'>{item}</h2>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data?.map((itemData, index) => (
                    <>
                      {index == 0 && <div className='row-space'></div>}
                      <tr key={index + '-table-alquimia'}>
                        {headers.map((itemHeader, index) => (
                          <td key={index + '-table-alquimia-td'}>
                            <span
                              className={`${(itemHeader == 'creatividad y sabor' ||
                                itemHeader == 'tragos servidos') &&
                                // 'shadow'
                                'average-number'
                                }`}
                            >
                              {itemData[itemHeader]}
                            </span>
                          </td>
                        ))}
                      </tr>
                      <div className='row-space'></div>
                    </>
                  ))}
              </tbody>
            </table>
          </div>

          {/* <div className="alquimia-table-pagination mt-3">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={Math.ceil(
                (1 * total ? total : 0) / pagePagination.limit
              )}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div> */}
        </div>
      )}
    </>
  )
}

export default VoteTable
