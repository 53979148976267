import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Redirect } from 'react-router-dom'

export const toastSweetAlert = async ({
  message,
  mode,
  icon = false,
  title,
  denyButtonText = 'Cancel',
  confirmButtonText = 'Ok',
  content = {},
  validator = () => {},
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  const MySwal = withReactContent(Swal)

  switch (mode) {
    case 'ok':
      // return Toast.fire({
      //   icon: 'success',
      //   title: `${message}`,
      // })

      return MySwal.fire({
        showCloseButton: true,
        icon: 'success',
        title,
        html: (
          <>
            <div className="errorModal">
              <div className="message-container">
                {message ? message : 'Exito.'}
              </div>
            </div>
          </>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
        focusConfirm: false,
        customClass: {
          title: 'StandarModalTitle',
          htmlContainer: 'StandarModalHtmlContainer',
          confirmButton: 'StandarModalConfirmButtonLogOut',
        },
      })

    case 'loading':
      return MySwal.fire({
        showCloseButton: false,
        title: ' ',
        html: (
          <>
            <div className="errorModal">
              <div className="message-container">
                {message ? message : 'Loading, please wait...'}
              </div>
            </div>
          </>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      })

    case 'error':
      return MySwal.fire({
        showCloseButton: true,
        title: 'Error',
        icon: 'error',
        html: (
          <>
            <div className="errorModal">
              <div className="message-container">
                {message ? message : 'Algo salió mal, vuelve a intentar.'}
              </div>
            </div>
          </>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showConfirmButton: true,
        focusConfirm: false,
        confirmButtonText: 'Ok',
        customClass: {
          title: 'StandarModalTitle',
          htmlContainer: 'StandarModalHtmlContainer',
          confirmButton: 'StandarModalConfirmButtonLogOut',
        },
      })

    case 'okModal':
      Swal.fire({
        title: 'Bien',
        text: message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-sm btn-accept',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        focusConfirm: false,
      })
      break

    case 'errorModal':
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'error',
        allowOutsideClick: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fas fa-check"> Ok</i>',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-sm btn-accept',
        },
      })
      break

    case 'finishOrder':
      return MySwal.fire({
        showCloseButton: true,
        title: 'Pedido agregado',
        html: (
          <>
            <div className="errorModal">
              <div className="message-container">
                {message ? message : 'Exito.'}
              </div>
            </div>
          </>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        confirmButtonText: 'Ver orden',
        cancelButtonText: 'Finalizar',
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        focusConfirm: false,
        customClass: {
          title: 'StandarModalTitle',
          htmlContainer: 'StandarModalHtmlContainer',
          confirmButton: 'StandarModalConfirmButtonLogOut',
          cancelButton: 'StandarModalCancelButtonLogOut',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('ver orden')
          window.location.replace('/telemarketingOrderHistory/details/2/status')
        } else {
          window.location.replace('/telemarketingOrderHistory')
        }
      })

    case 'modalConfirmCancel':
      return MySwal.fire({
        showCloseButton: true,
        icon: icon,
        title: title,
        html: (
          <>
            <div className="message-container">{message}</div>
          </>
        ),
        allowOutsideClick: false,
        buttonsStyling: false,
        showDenyButton: true,
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText,
        reverseButtons: true,
        customClass: {
          title: 'StandarModalTitle',
          htmlContainer: 'StandarModalHtmlContainer',
          confirmButton: 'StandarModalConfirmButtonLogOut',
          denyButton: 'StandarModalCancelButtonLogOut',
          footer: 'StandarModalFooterLogOut',
        },
      }).then(async (result) => {
        return result
      })

    case 'modalConfirmContent':
      return MySwal.fire({
        showCloseButton: true,
        icon: icon,
        title: title,
        html: content,
        focusConfirm: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        showDenyButton: true,
        confirmButtonText: confirmButtonText,
        denyButtonText: denyButtonText,
        reverseButtons: true,
        customClass: {
          title: 'StandarModalTitle',
          htmlContainer: 'StandarModalHtmlContainer',
          confirmButton: 'StandarModalConfirmButtonLogOut',
          denyButton: 'StandarModalCancelButtonLogOut',
          footer: 'StandarModalFooterLogOut',
        },
        preConfirm: validator,
      }).then(async (result) => {
        return result
      })

    default:
    case 'messageModal':
      Swal.fire({
        text: message,
        customClass: {
          confirmButton: 'btn btn-sm btn-accept',
        },
      })
      break
  }
}
