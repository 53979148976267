import { gql } from '@apollo/client'

export const GET_CALIFICATIONS_TYPES = gql`
  query GetCalificationsTypes {
    getCalificationsTypes {
        id
        name
    }
  }
`

export const GET_DRINK_CALIFICATION_BY_DRINK_ID = gql`
  query GetDrinkCalificationByDrinkId($drinkId: Int!) {
    getDrinkCalificationByDrinkId(drinkId: $drinkId) {
      drinks {
        id
        id_calification_type
        calification
      }
      voteExpired
    }
  }
`