import React, { useContext } from 'react'
import moment from 'moment'
import { AuthContext } from '../../Auth/AuthContext'
export const Footer = () => {
  const { user } = useContext(AuthContext)

  return (
    <footer className={`main-footer ${(user.role == 2 || user.role == 3) && "footer-client"}`}>
      <strong>
        Copyright &copy; {moment().format('YYYY')}{' '}
        <a href="https://google.com">Enigmatech</a>
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>
  )
}
export default Footer
