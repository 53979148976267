import { gql } from '@apollo/client'

export const GET_DRINKS = gql`
  query GetDrinks($searchQuery: String, $limit: Int, $offset: Int) {
    getDrinks(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        title
        id_file
        image {
          url
        }
        bar_name
        id_file_bar
        image_bar {
          url
        }
        drink_name
      }
      count
    }
  }
`

export const GET_DRINK_BY_ID = gql`
  query GetDrinkById($id: Int!) {
    getDrinkById(id: $id) {
      id
      title
      id_file
      image {
        url
      }
      bar_name
      id_file_bar
      image_bar {
        url
      }
      drink_name
    }
  }
`

export const CREATE_DRINK = gql`
  mutation CreateDrink($input: DrinkInput!) {
    createDrink(input: $input) {
      id
      title
    }
  }
`

export const UPDATE_DRINK = gql`
  mutation UpdateDrink($drinkId: Int!, $input: DrinkInput!) {
    updateDrink(drinkId: $drinkId, input: $input)
  }
`

export const DELETE_DRINK = gql`
  mutation DeleteDrink($id: Int!) {
    deleteDrink(id: $id)
  }
`

export const CALIFICATE_DRINK = gql`
  mutation CalificateDrink($drinkId: Int!, $input: [CalificateDrink!]!) {
    calificateDrink(drinkId: $drinkId, input: $input)
  }
`
export const GET_DRINKS_AVARAGE = gql`
  query GetDrinksAvarage($searchQuery: String, $limit: Int, $offset: Int) {
    getDrinksAvarage(
      searchQuery: $searchQuery
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        drink {
          id
          title
          id_file
          image {
            id
            url
          }
          bar_name
          id_file_bar
          image_bar {
            id
            url
          }
          drink_name
        }
        avarage
        drinks_served
      }
    }
  }
`

export const EXPORTS_DRINKS = gql`
  mutation ExportDrinks {
    exportDrinks {
      data {
        calification
        drink
        bar
        quantity
        type
      }
      headers {
        key
        label
      }
    }
  }
`