import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import Logo from './../../Assets/Images/vinos-america-logo.png'
import { SEND_PASSWORD_TO_EMAIL } from '../../graphql/Catalog/Users/user'

export const RecoveryPassword = () => {
  const [form, setForm] = useState({
    email: '',
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [sendPasswordToEmail] = useMutation(SEND_PASSWORD_TO_EMAIL)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (form.email === '')
      return toastSweetAlert({
        mode: 'error',
        message: 'Ingrese su correo electrónico',
      })

    setLoading(true)
    try {
      await sendPasswordToEmail({
        variables: {
          email: form.email,
        },
      })
      setLoading(false)
      return toastSweetAlert(
        {
          mode: 'okModal',
          message:
            'Se ha enviado tu nueva contraseña en el correo',
        },
        history.push('/login')
      )
    } catch (error) {
      setLoading(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  return (
    <div className="full-container recovery-box">
      <div className="row mb-5 mt-4">
        <div className="col-12 image-box">
          <img src={Logo} className="img-logo-full m-4" alt="logo" />
        </div>
      </div>
      <span className="mb-2">Recuperar contraseña</span>
      <div className="row">
        <div className="col-12 p-3">
          <form onSubmit={handleSubmit} method="post" className="row">
            <div className="input-icons-login col-12 iconInside">
              <i className="far fa-user"></i>
              <input
                id="user"
                type="text"
                className="form-control"
                placeholder="Ingrese su correo electrónico"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </div>
            <p className="col-12 mt-2 mb-2 text">
              Ingrese su correo electrónica para mandar un codigo para resetear
              su contraseña{' '}
            </p>
            <div className="login-btn col-12">
              {loading ? (
                <button className="btn btn-recovery btn-sm" type="button" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Procesando...</span>
                </button>
              ) : (
                <button type="submit" className="btn btn-recovery btn-sm">
                  Enviar
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RecoveryPassword
