import React, { useContext, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LayoutLogged } from '../Components/Layout/LayoutLogged'
import { AuthContext } from '../Auth/AuthContext'
import { types } from '../Types/types'
import { useHistory } from 'react-router'

export const PrivateRoute = ({ isAuth, component: Component, ...rest }) => {
  const { dispatch } = useContext(AuthContext)
  const history = useHistory()
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch({
        type: types.logout,
      })
      return <Redirect to="/login" />
    }
    if (localStorage.getItem('pageRequest')) {
      const path = localStorage.getItem('pageRequest')
      return history.push(path),localStorage.removeItem('pageRequest')
    }
  }, [])

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuth ? (
          <LayoutLogged>
            <Component {...props} />
          </LayoutLogged>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

PrivateRoute.prototype = {
  isAuth: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
}
