import { ApolloClient, InMemoryCache, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'

//WebSockets
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPH_SERVER, //LOCALHOST
  //uri: 'https://newsuite.vinosamerica.com/graphql', //PRODUCTIVO
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  const requestPath = window.location.pathname
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      requestPath,
    },
  }
})

//Web sockets
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPH_WS_SERVER,
  options: {
    reconnect: true,
  },
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink)
)
//End Web sockets

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({ addTypename: false }),
  link,
})

export default client
