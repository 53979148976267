import { gql } from '@apollo/client'

export const GET_ALL_USER_ROLES_PAGINATION = gql`
  query GetAllUserRoles($searchQuery: String, $limit: Int, $offset: Int) {
    getAllUserRoles(searchQuery: $searchQuery, limit: $limit, offset: $offset) {
      rows {
        id
        name
        permissions
      }
      count 
    }
  }
`

export const GET_ALL_USER_ROLES_NO_PAGINATION = gql`
  query GetAllUserRolesNoPagination {
    getAllUserRolesNoPagination {
      id
      name
    }
  }
`

export const GET_USER_ROLE_BY_ID = gql`
  query GetUserRoleById($id: Int!) {
    getUserRoleById(id: $id) {
      id
      name
      permissions
    }
  }
`

export const DELETE_USER_ROLE = gql`
  mutation DeleteUserRole($id: Int!) {
    deleteUserRole(id: $id)
  }
`

export const CREATE_USER_ROLE = gql`
  mutation CreateUserRole($name: String!) {
    createUserRole(name: $name)
  }
`

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: Int!, $name: String!) {
    updateUserRole(id: $id, name: $name)
  }
`
