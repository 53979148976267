import { useMutation, useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ContentHeader from '../../Layout/ContentHeader'
import { FormProvider, useForm } from 'react-hook-form'
import InputController from '../../Global/InputController'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSweetAlert } from '../../Helpers/ToastSweetAlert'
import Box from '../../Global/Box'
import {
  CREATE_DRINK,
  GET_DRINKS,
  GET_DRINKS_AVARAGE,
  GET_DRINK_BY_ID,
  UPDATE_DRINK,
} from '../../../graphql/Catalog/Drinks/drinks'
import { AuthContext } from '../../../Auth/AuthContext'
import DrinksVotes from './DrinksVotes'

const DrinksNew = () => {
  const { id: _id, show } = useParams()
  const { user: _user } = useContext(AuthContext)
  const history = useHistory()

  //------ Hooks to save data ------
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [previewPic, setPreviewPic] = useState(null)
  const [previewPicBar, setPreviewPicBar] = useState(null)

  //------ Queries and Mutations ------
  const { data, loading, error } = useQuery(GET_DRINK_BY_ID, {
    variables: {
      id: parseInt(_id) ? parseInt(_id) : 0,
    },
  })
  const [CreateDrink] = useMutation(CREATE_DRINK, {
    refetchQueries: [
      {
        query: GET_DRINKS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_DRINKS_AVARAGE,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  const [UpdateDrink] = useMutation(UPDATE_DRINK, {
    refetchQueries: [
      {
        query: GET_DRINKS,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
      {
        query: GET_DRINK_BY_ID,
        variables: {
          id: parseInt(_id) ? parseInt(_id) : 0,
        },
      },
      {
        query: GET_DRINKS_AVARAGE,
        variables: {
          limit: 10,
          offset: 0,
          searchQuery: null,
        },
      },
    ],
  })

  //------ Yup schema -----
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Este campo es requerido')
      .max(30, 'El titulo debe tener como maximo 30 letras'),
    image: Yup.string().required('Este campo es obligatorio'),
    bar_name: Yup.string()
      .required('Este campo es requerido')
      .max(30, 'El titulo debe tener como maximo 30 letras'),
    image_bar: Yup.string(),
    drink_name: Yup.string().max(
      30,
      'El titulo debe tener como maximo 30 letras'
    ),
  })
  //------ Yup form -----
  const {
    methods,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  //------- UseEffects -------
  useEffect(() => {
    if (!loading && _id) {
      if (error) {
        setLoadingBtn(false)
        return toastSweetAlert({
          mode: 'error',
          message: error.message,
        },history.push(`/`))
      }
      //Get data
      if (data.getDrinkById) {
        setValue('title', data.getDrinkById.title)
        setValue('bar_name', data.getDrinkById.bar_name)
        setValue('image', data?.getDrinkById?.image?.url)
        setValue('image_bar', data?.getDrinkById?.image_bar?.url)
        setValue('drink_name', data.getDrinkById.drink_name)
        setPreviewPic(data?.getDrinkById?.image?.url)
        setPreviewPicBar(data?.getDrinkById?.image_bar?.url)
      }
    }
  }, [data, loading, error])
  //------- Functions --------
  const onSubmit = async (Data) => {
    if (show) return
    setLoadingBtn(true)
    try {
      const { title, bar_name, drink_name } = Data

      if (_id)
        await UpdateDrink({
          variables: {
            drinkId: parseInt(_id),
            input: {
              title,
              drink_name,
              bar_name,
              image:
                typeof getValues('image') === 'object'
                  ? getValues('image')
                  : null,
              image_bar:
                typeof getValues('image_bar') === 'object'
                  ? getValues('image_bar')
                  : null,
            },
          },
        })
      else
        await CreateDrink({
          variables: {
            input: {
              title,
              drink_name,
              bar_name,
              image:
                typeof getValues('image') === 'object'
                  ? getValues('image')
                  : null,
              image_bar:
                typeof getValues('image_bar') === 'object'
                  ? getValues('image_bar')
                  : null,
            },
          },
        })

      setLoadingBtn(false)
      return toastSweetAlert(
        {
          mode: 'ok',
          title: 'Éxito',
          message: `La bebida ha sido ${_id ? 'actualizada' : 'creada'
            } con éxito`,
        },
        history.push('/drinks')
      )
    } catch (error) {
      setLoadingBtn(false)
      return toastSweetAlert({
        mode: 'error',
        message: error.message,
      })
    }
  }

  useEffect(() => {
    const url = history.location.pathname;

    if (url == "/drinks/details/1/show") {
      const nav = document.querySelector("#navbar")
      console.log(nav, "NAV")
      nav.classList.add("bg-green")
    }


  }, []);

  return (
    <>
      {_user.role != 2 ? (
        <>
          <ContentHeader
            title="Bebida"
            breadcrumb="Bebida"
            windowTitle={`${_id ? 'Editar' : 'Agregar'} bebida `}
          />
          <FormProvider {...methods}>
            <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
              <Box
                title={`${_id ? 'Editar' : 'Agregar nueva'} bebida`}
                btnRedPath="/drinks"
                btnRedTxt="Cancelar"
                errors={errors}
                btnSubmit={!show}
                btnLoading={loadingBtn}
                content={
                  <>
                    <div className="row">
                      <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-center">
                            <InputController
                              label="Imagen de la marca"
                              inputType="image"
                              name="image"
                              previewPic={previewPic}
                              validateFormat="imageAvatar"
                              validateFormatMsg="Solo se admite JPG, JPGE y PNG"
                              control={control}
                              disabled={show}
                            />
                            <label htmlFor="image">Imagen de la marca</label>
                          </div>
                          <div className="col-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 text-center">
                            <InputController
                              label="Imagen del bar"
                              inputType="image"
                              name="image_bar"
                              previewPic={previewPicBar}
                              validateFormat="imageAvatar"
                              validateFormatMsg="Solo se admite JPG, JPGE y PNG"
                              control={control}
                              disabled={show}
                            />
                            <label htmlFor="image">Imagen del bar</label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                        <InputController
                          label="Destilado"
                          name="title"
                          type="text"
                          placeholder="Destilado"
                          control={control}
                          disabled={show}
                        />
                      </div>
                      <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                        <InputController
                          label="Nombre del bar"
                          name="bar_name"
                          type="text"
                          placeholder="Nombre del bar"
                          control={control}
                          disabled={show}
                        />
                      </div>
                      <div className="mb-3 col-lg-12 col-md-12 col-sm-12">
                        <InputController
                          label="Nombre del trago"
                          name="drink_name"
                          type="text"
                          placeholder="Nombre del trago"
                          control={control}
                          disabled={show}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </form>
          </FormProvider>
        </>
      ) : (
        <>
          {_id && show && (
            <DrinksVotes
              drinkId={parseInt(_id)}
              title={getValues('title')}
              img={previewPic}
              imgBar={previewPicBar}
              barName={getValues('bar_name')}
              drinkName={getValues('drink_name')}
            />
          )}
        </>
      )}
    </>
  )
}

export default DrinksNew
