import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { DELETE_APP_USER, GET_ALL_APP_USER_PAGINATION } from '../../../../graphql/Catalog/AppUsers/AppUser'

import LayoutTable from '../../../Global/LayoutTable'
import { toastSweetAlert } from '../../../Helpers/ToastSweetAlert'
import ContentHeader from '../../../Layout/ContentHeader'

const UsersApp = () => {
  //------- Hooks --------
  const [pagePagination, setPagePagination] = useState({
    searchQuery: null,
    limit: 10,
    offset: 0,
  })

  //To save all area data
  const [list,setList] = useState([])
  //To save total register
  const [total, setTotal] = useState(0)

  const {
    loading,
    data,
    error
  } = useQuery(GET_ALL_APP_USER_PAGINATION,{
    variables: {
      searchQuery: pagePagination.searchQuery,
      limit: pagePagination.limit,
      offset: pagePagination.offset,
    }
  })

  //------- UseEffects --------
  useEffect(() => {
    if(!loading){
      if(error)
        toastSweetAlert({
          mode:'error',
          message:error.message,
        })

      let list = []
      data?.getAllAppUsersPagination?.rows.map((item)=>{
        list.push({
          id: item.id,
          name: item.name,
          username: item.username,
        })
      })
      setList(list)
      setTotal(data?.getAllAppUsersPagination?.count)
    }
  }, [loading,data,error])

  return (
    <>
      <ContentHeader
        title="App user"
        breadcrumb="App user"
        windowTitle="App user"
      />
      <LayoutTable
        exportImportButton={false}
        data={list}
        title="App user"
        gql={DELETE_APP_USER}
        requery={GET_ALL_APP_USER_PAGINATION}
        // exportQuery={EXPORT_ROLES}
        totalCount={total}
        pagePagination={pagePagination}
        setPagePagination={setPagePagination}
        loadingData={loading}
        FilterBarPlaceholder= "Search by Name or Username"
        // hideFilterBar
      />
    </>
  )
}
export default UsersApp
